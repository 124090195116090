import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { useLocation } from "react-router-dom";
import scrollToRef from "../utils/scrollTarget";

const IntroScaleData = [
  {
    title: "introInfoFoundingDate",
    contents: ["introInfoFoundingDateValue"],
    icon: <Icons.IconFounding2 />,
  },
  {
    title: "introInfoScale",
    contents: ["introInfoScaleValue"],
    icon: <Icons.IconScale2 />,
  },
  {
    title: "introInfoPartner",
    contents: ["introInfoPartnerValue"],
    icon: <Icons.IconPartner3 />,
  },
];
const HistoryData = {
  newHistory: [
    {
      year: /*"introHistoryterm3"*/ "",
      activity: [],
    },
    {
      year: "2024",
      activity: ["introHistory24_1", "introHistory24_2"],
    },
    {
      year: "2023",
      activity: ["introHistory23_1", "introHistory23_2", "introHistory23_3"],
    },
    {
      year: "2022",
      activity: [
        "introHistory22_1",
        "introHistory22_2",
        "introHistory22_3",
        "introHistory22_4",
        "introHistory22_5",
        "introHistory22_6",
      ],
    },
    {
      year: "2020",
      activity: ["introHistory20_1", "introHistory20_2", "introHistory20_3"],
    },
    {
      year: "2019",
      activity: [
        "introHistory19_1",
        "introHistory19_2",
        "introHistory19_3",
        "introHistory19_4",
        "introHistory19_5",
        "introHistory19_6",
        "introHistory19_7",
        "introHistory19_8",
      ],
    },
    {
      year: "2018",
      activity: [
        "introHistory18_1",
        "introHistory18_2",
        "introHistory18_3",
        "introHistory18_4",
      ],
    },
    {
      year: "2017",
      activity: ["introHistory17_1", "introHistory17_2"],
    },
    {
      year: "2016",
      activity: ["introHistory16_1", "introHistory16_2", "introHistory16_3"],
    },
  ],
  oldHistory: [
    {
      year: "2015",
      activity: ["introHistory15_1", "introHistory15_2"],
    },
    {
      year: "2014",
      activity: ["introHistory14_1", "introHistory14_2"],
    },
    {
      year: "2013",
      activity: ["introHistory13_1", "introHistory13_2", "introHistory13_3"],
    },
    {
      year: "2012",
      activity: ["introHistory12_1"],
    },
    {
      year: "2010",
      activity: ["introHistory10_1", "introHistory10_2"],
    },
    {
      year: "2009",
      activity: ["introHistory09_1"],
    },
  ],
};
const ContactData = [
  {
    location: "introLocation_1",
    address: "introAddress_1",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.9918316352764!2d127.11946237637802!3d37.48451912882609!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f654a27e05f%3A0x1c94e486c2c154f2!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCk!5e0!3m2!1sko!2skr!4v1684820097086!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
    call: "02 - 827 - 0020",
    fax: "02 - 828 - 5377",
  },
  {
    location: "introLocation_2",
    address: "introAddress_2",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.5247970773116!2d126.96941307638022!3d37.54269772549399!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2068f8f09df%3A0xd93536ffb2b4e29a!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg7Jqp7IKw7KeA7KCQ!5e0!3m2!1sko!2skr!4v1685500430387!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
    call: "1811 - 7247",
  },
  {
    location: "introLocation_3",
    address: "introAddress_3",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.3198034526654!2d126.75613067638528!3d37.6886878171136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c8fdee59edda9%3A0x6f33aec492f181!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg6rK96riw7KeA7KCQ!5e0!3m2!1sko!2skr!4v1685500324245!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
    call: "02 - 323 - 9461",
    fax: "02 - 323 - 9684",
  },
  {
    location: "introLocation_4",
    address: "introAddress_4",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.387397507254!2d129.12573307630086!3d35.171895857784214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356893bd1b0840f3%3A0x47058929279573d5!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg67aA7IKw7KeA7IKs!5e0!3m2!1sko!2skr!4v1685500356217!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
    call: "010 - 2830 - 4242",
  },
  {
    location: "introLocation_5",
    address: "introAddress_5",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.05439984229!2d127.37630667633995!3d36.35946119241917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35654b6f5364c983%3A0x322c51ff1ab6e6df!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg64yA7KCE7KeA7IKs!5e0!3m2!1sko!2skr!4v1685500377371!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
  },
  {
    location: "introLocation_6",
    address: "introAddress_6",
    map: `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.9055382306706!2d126.95177678906866!3d37.53372395394575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca3cb1d2030f5%3A0xa663af77e99d4240!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg7Jqp7IKwIOusvOulmOyEvO2EsA!5e0!3m2!1sko!2skr!4v1685500398803!5m2!1sko!2skr" width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
  },
];

const IntroStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;

const IntroMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: #12363b;
    background: url("/background/intro-12.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      word-break: keep-all;
      margin: auto;
      background: #00000099;
      color: #fff;
      width: 100%;
      padding: 40px 0;
      text-align: center;
      h1 {
        line-height: 1.2em;
        font-size: 60px;
        max-width: 800px;
        margin: auto;
      }
      p {
        max-width: 720px;
        margin: 30px auto auto;
      }
    }
  }

  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const IntroMain = ({ t }: any) => {
  return (
    <IntroMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleY(0)", opacity: 0 }}
          animate={{ transform: "scaleY(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("introMain01")}
          </motion.h1>
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("introMain01_1")}
          </motion.h1>
        </motion.div>
      </div>
    </IntroMainStyle>
  );
};

const IntroHistoryStyle = styled.div`
  background-image: linear-gradient(to right, #ffffff66, #fff 50%),
    url("/background/intro-4.jpg");
  background-size: cover;
  .scroll {
    height: unset;
  }
  .subtitle {
    max-width: 1200px;
    width: 95%;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
    font-size: 33px;
    color: #2d59a2;
    margin: 50px auto;
    background: #ffffff22;
    word-break: keep-all;
  }
  .history-frame {
    margin: auto;
    max-width: 1200px;
    width: 95%;
    height: inherit;
    display: flex;
    flex-direction: column;
    position: relative;
    > * {
      z-index: 1;
    }
  }
  h1 {
    text-align: center;
    padding: 30px 0;
    font-size: 40px;
  }
  .history-select {
    margin: 0 auto;
    display: flex;
    width: 100%;
    border-bottom: 2px solid #2d59a2;
    color: #2d59a2;
    .button {
      font-size: 24px;
      font-weight: bold;
      min-width: 200px;
      width: 100%;
      text-align: center;
      height: 60px;
      display: flex;
      background: #fff;
      transition: 0.3s;
      p {
        margin: auto;
      }
    }
    .button.active {
      color: #fff;
      background: #2d59a2;
    }
  }
  .history-description {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    .history-slogan {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 50%;
      margin-bottom: 20px;
      margin-right: 60px;
      line-height: 1.6em;
      h2 {
        font-size: 30px;
        margin-bottom: 30px;
      }
      p {
        ::before {
          content: "";
          background: #2d59a2;
          display: inline-block;
          width: 5px;
          height: 1em;
          margin: 0px 10px -2px 0;
        }
      }
    }
    .history-box {
      overflow: auto;
      height: 100%;
      width: 700px;
      min-width: 500px;
      display: flex;
      flex-direction: column;
      .history-contents {
        width: 100%;
        display: flex;
        .history-contents-left {
          display: flex;
          flex-direction: column;
          margin-right: 100px;
          word-break: keep-all;
          min-width: 110px;
          h2 {
            font-size: 45px;
            line-height: 1em;
            text-align: center;
            color: #2d59a2;
          }
        }
        .history-contents-right {
          width: 100%;
          margin-bottom: 30px;
          min-height: 60px;
          li {
            font-size: 18px;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .divider-v {
    height: 100%;
    padding: 20px 0;
    &::after {
      content: "";
      display: flex;
      margin: 0px auto;
      width: 1px;
      height: 100%;
      background: #2d59a2;
    }
  }
  @media (max-width: 768px) {
    .subtitle {
      font-size: 20px;
      padding: 20px;
      margin: 10px auto;
    }
    h1 {
      padding: 20px 0;
      font-size: 25px;
    }
    .history-select {
      margin: 30px auto;
      width: 100%;
      .button {
        height: 36px;
        min-width: unset;
        font-size: 16px;
      }
    }
    .history-description {
      display: unset;
      margin: unset;
      .history-slogan {
        display: unset;
        max-width: unset;
        width: unset;
        margin-bottom: unset;
        margin-right: unset;
        line-height: unset;
        h2 {
          font-size: 25px;
          line-height: 22px;
        }
      }
      .history-box {
        margin: 30px auto;
        width: 100%;
        min-width: unset;
        .history-contents {
          .history-contents-left {
            margin-right: 10px;
            h2 {
              font-size: 20px;
            }
          }
          .history-contents-right {
            min-height: 40px;
            li {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
const IntroHistory = ({ t }: any) => {
  const [isNewHistory, setIsNewHistory] = useState(true);
  return (
    <IntroHistoryStyle>
      <div className="scroll">
        <br />
        <h1 className="subtitle">
          {t("introMain02_1")}
          {/* <br />
          {t("introMain02_2")} */}
        </h1>

        <div className="history-frame">
          <h1>{t("introHistory")}</h1>
          <div className="history-select">
            <div
              onClick={() => setIsNewHistory(true)}
              className={`button pointer ${isNewHistory ? "active" : ""}`}
            >
              <p>{t("introHistoryterm1")}</p>
            </div>
            <div
              onClick={() => setIsNewHistory(false)}
              className={`button pointer ${isNewHistory ? "" : "active"}`}
            >
              <p>{t("introHistoryterm2")}</p>
            </div>
          </div>
          <section className="history-description">
            <div className="history-slogan">
              {isNewHistory ? (
                <>
                  <h2 style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ marginBottom: 15 }}>
                      {t("introHistorySlogan1")}
                    </span>
                    <span>{t("introHistorySlogan1_0")}</span>
                  </h2>
                  <p>{t("introHistorySlogan1_1")}</p>
                  <p>{t("introHistorySlogan1_2")}</p>
                </>
              ) : (
                <h2 style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ marginBottom: 15 }}>
                    {t("introHistorySlogan2")}
                  </span>
                  <span>{t("introHistorySlogan2_0")}</span>
                </h2>
              )}
            </div>
            <ul className="history-box">
              {isNewHistory
                ? HistoryData.newHistory.map((res, idx) => {
                    return (
                      <li className="history-contents" key={idx}>
                        <div className="history-contents-left">
                          <h2>{t(res.year)}</h2>
                          <p className="divider-v"></p>
                        </div>
                        <ul className="history-contents-right">
                          {res.activity.map((res2, idx2) => {
                            return <li key={idx2}>{t(res2)}</li>;
                          })}
                        </ul>
                      </li>
                    );
                  })
                : HistoryData.oldHistory.map((res, idx) => {
                    return (
                      <li className="history-contents" key={idx}>
                        <div className="history-contents-left">
                          <h2>{res.year}</h2>
                          <p className="divider-v"></p>
                        </div>
                        <ul className="history-contents-right">
                          {res.activity.map((res2, idx2) => {
                            return <li key={idx2}>{t(res2)}</li>;
                          })}
                        </ul>
                      </li>
                    );
                  })}
            </ul>
          </section>
        </div>
      </div>
    </IntroHistoryStyle>
  );
};

const IntroContactStyle = styled.div`
  .scroll {
    display: flex;
    min-height: unset;
    max-width: 1200px;
    width: 95%;
    margin: 50px auto;
  }
  .contact-frame {
    margin: auto;
    width: 100%;
    .contact-select {
      line-height: 1.2em;
      word-break: keep-all;
      display: flex;
      border-bottom: 2px solid #2d59a2;
      color: #2d59a2;
      li {
        width: 100%;
        display: flex;
        height: 60px;
        p {
          margin: auto;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }
    .contact-box {
      overflow: auto;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      .contact-location {
        display: flex;
        margin-right: 5px;
        svg {
          max-height: 20px;
          width: 20px;
          margin: auto;
          path {
            fill: #2d59a2;
          }
        }
      }
      div {
        margin-top: 10px;
      }
      p {
        display: flex;
        font-size: 16px;
        padding: 5px 0;
      }
    }
  }
  h1 {
    text-align: center;
    padding: 30px 0;
    font-size: 40px;
  }
  .button {
    min-width: 120px;
    text-align: center;
    padding: 5px 15px;
    background: #fff;
    transition: 0.3s;
    &.active {
      color: #fff;
      background: #2d59a2;
    }
  }
  @media (max-width: 768px) {
    .contact-frame {
      .contact-select {
        flex-wrap: wrap;
        li {
          width: calc(100% / 3);
          height: 36px;
          p {
            font-size: 16px;
          }
        }
      }
      .contact-box {
        margin: 10px auto;
        p {
          font-size: 14px;
          .contact-location {
            width: 20px;
            svg {
              margin-top: 3px;
            }
          }
        }
      }
    }
    h1 {
      padding: unset;
      padding-bottom: 20px;
      font-size: 25px;
    }
    .button {
      min-width: unset;
      padding: unset;
    }
  }
`;
const IntroContact = ({ t, i18n }: any) => {
  const [contact, setContact] = useState(t("introLocation_1"));
  useEffect(() => {
    setContact(t("introLocation_1"));
  }, [i18n.language]);

  const contactData = ContactData.filter(
    (data) => t(data.location) === t(contact) && data
  );
  return (
    <IntroContactStyle>
      <div className="scroll">
        <div className="contact-frame" id="contact-frame">
          <h1>{t("introContact")}</h1>
          <ul className="contact-select">
            {ContactData.map((res, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => setContact(t(res.location))}
                  className={`button pointer ${
                    t(res.location) === contact ? "active" : ""
                  }`}
                >
                  <p className="contact-contents" key={idx}>
                    {t(res.location)}
                  </p>
                </li>
              );
            })}
          </ul>
          {contactData.map((res, idx) => {
            return (
              <ul key={idx} className="contact-box">
                <p>
                  <span className="contact-location">
                    <Icons.IconLocation></Icons.IconLocation>
                  </span>
                  <span>{t(res.address)}</span>
                </p>
                <div dangerouslySetInnerHTML={{ __html: res.map }}></div>
              </ul>
            );
          })}
        </div>
      </div>
    </IntroContactStyle>
  );
};

const Intro = ({ t, i18n }: any) => {
  const location = useLocation();
  const up1 = useRef(null);
  const up2 = useRef(null);
  const up3 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    switch (location.state) {
      case 0:
        scrollToRef(up1, location);
        break;
      case 1:
        scrollToRef(up2, location);
        break;
      case 2:
        scrollToRef(up3, location);
        break;
      default:
        break;
    }
  }, [location.state]);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    up1.current && observer.observe(up1.current);
    up2.current && observer.observe(up2.current);
    up3.current && observer.observe(up3.current);
  });
  return (
    <IntroStyle className="no-select">
      <IntroMain t={t} />
      <section className="up-soon" ref={up1}>
        <IntroHistory t={t} />
      </section>
      <section className="up-soon" ref={up2}>
        <IntroContact t={t} i18n={i18n} />
      </section>
    </IntroStyle>
  );
};

export default Intro;
