import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Icons from "../assets/icons";
import Modal from "./Modal";
import { Link, useLocation } from "react-router-dom";
import scrollToRef from "../utils/scrollTarget";
interface dataProps {
  main: string;
  hover: string;
  active: string;
  background: string;
}
const data = [
  {
    head: {
      title: "DELL Technologies",
      color: {
        main: "#007DB8",
        hover: "#0097dd",
        active: "#00699a",
        background: "#F3F9FD",
      },
      mainLogo: <img alt="seagate-logo" src="/icons/brand-dell.png"></img>,
      type: "dell",
      homeLink: "https://www.dell.com/ko-kr",
      description: ["dellDescription", "dellDescription1", "dellDescription2"],
    },
    item: [
      {
        title: "Server",
        pageLink: "https://www.dell.com/ko-kr/dt/servers/index.htm",
        itemList: [
          {
            name: "dell_1_con_1",
            description: "dell_1_con_1_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-1-1.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/servers/poweredge-tower-servers.htm#tab0=0",
          },
          {
            name: "dell_1_con_2",
            description: "dell_1_con_2_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-1-2.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/servers/poweredge-rack-servers.htm#tab0=0&tab1=0&accordion0",
          },
          {
            name: "dell_1_con_3",
            description: "dell_1_con_3_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-1-3.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/servers/modular-infrastructure/index.htm#tab0=0",
          },
        ],
      },
      {
        title: "Storage",
        pageLink: "https://www.dell.com/ko-kr/dt/storage/data-storage.htm",
        itemList: [
          {
            name: "Power Max",
            description: "dell_2_con_1_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-1.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/storage/powermax.htm#tab0=0",
          },
          {
            name: "Power Scale",
            description: "dell_2_con_2_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-2.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/storage/powerscale.htm#tab0=0",
          },
          {
            name: "ECS",
            description: "dell_2_con_3_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-3.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/storage/ecs/index.htm#tab0=0&tab1=0",
          },
          {
            name: "Power Store",
            description: "dell_2_con_4_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-4.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/storage/powerstore-storage-appliance.htm#tab0=0",
          },
          {
            name: "Unity XT",
            description: "dell_2_con_5_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-5.png')" }} />
            ),
            itemLink: "https://www.dell.com/ko-kr/dt/storage/unity.htm#tab0=0",
          },
          {
            name: "Power Vault",
            description: "dell_2_con_6_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-2-6.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/dt/storage/powervault-me5.htm#tab0=0",
          },
        ],
      },
      {
        title: "Client",
        pageLink: "https://www.dell.com/ko-kr",
        itemList: [
          {
            name: "Display",
            description: "dell_3_con_1_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-1.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/%EB%AA%A8%EB%8B%88%ED%84%B0-%EB%B0%8F-%EB%AA%A8%EB%8B%88%ED%84%B0-%EC%95%A1%EC%84%B8%EC%84%9C%EB%A6%AC/ac/4009",
          },
          {
            name: "Notebook",
            description: "dell_3_con_4_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-4.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/dell-%EB%85%B8%ED%8A%B8%EB%B6%81/sc/laptops",
          },
          {
            name: "Desktop",
            description: "dell_3_con_2_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-2.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/dell-%EB%8D%B0%EC%8A%A4%ED%81%AC%ED%83%91/sc/desktops",
          },
          {
            name: "Workstation",
            description: "dell_3_con_3_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-3.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/%EC%9B%8C%ED%81%AC%EC%8A%A4%ED%85%8C%EC%9D%B4%EC%85%98-isv-%EC%9D%B8%EC%A6%9D-%EB%B0%8F-%EC%B5%9C%EC%A0%81%ED%99%94/sc/workstations",
          },
          {
            name: "Tower/Rack Workstation",
            description: "dell_3_con_5_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-5.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/sfc/sf/precision-desktops",
          },
          {
            name: "dell_3_con_6",
            description: "dell_3_con_6_des",
            itemImg: (
              <div style={{ backgroundImage: "url('/icons/dell-3-6.png')" }} />
            ),
            itemLink:
              "https://www.dell.com/ko-kr/shop/%EB%AA%A8%EB%8B%88%ED%84%B0-%EB%B0%8F-%EB%AA%A8%EB%8B%88%ED%84%B0-%EC%95%A1%EC%84%B8%EC%84%9C%EB%A6%AC/ac/4009",
          },
        ],
      },
    ],
  },
  {
    head: {
      title: "SEAGATE",
      color: {
        main: "#6FBE4B",
        hover: "#8ae261",
        active: "#599f38",
        background: "#f5fdf1",
      },
      mainLogo: <img alt="seagate-logo" src="/icons/brand-seagate.png"></img>,
      type: "seagate",
      homeLink: "https://www.seagate.com/kr/ko/",
      description: [
        "seagateDescription",
        "seagateDescription1",
        "seagateDescription2",
      ],
    },
    item: [
      {
        title: "Storage",
        pageLink:
          "https://www.seagate.com/kr/ko/products/storage/data-storage-systems/",
        itemList: [
          {
            name: "CORVAULT",
            description: "seagate_1_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-1-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/storage/data-storage-systems/corvault/",
          },
          {
            name: "All-Flash Disk Arrays",
            description: "seagate_1_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-1-2.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/storage/data-storage-systems/raid/",
          },
          {
            name: "Converged Platforms",
            description: "seagate_1_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-1-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/storage/data-storage-systems/jbod/exos-e-5u84/",
          },
        ],
      },
      {
        title: "Enterprise",
        pageLink: "https://www.seagate.com/kr/ko/products/enterprise-drives/",
        itemList: [
          {
            name: "Exos 7E10",
            description: "seagate_2_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-2-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/enterprise-drives/exos-e/7e10/",
          },
          {
            name: "Exos X20",
            description: "seagate_2_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-2-2.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/enterprise-drives/exos-x/x20/",
          },
          {
            name: "Nytro SSD",
            description: "seagate_2_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-2-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/support/enterprise-storage/solid-state-drives/nytro-5000/",
          },
        ],
      },
      {
        title: "Business NAS",
        pageLink: "https://www.seagate.com/kr/ko/products/nas-drives/",
        itemList: [
          {
            name: "IronWolf",
            description: "seagate_3_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-3-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/nas-drives/ironwolf-hard-drive/",
          },
          {
            name: "IronWolf Pro",
            description: "seagate_3_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-3-2.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/nas-drives/ironwolf-hard-drive/",
          },
          {
            name: "IronWolf SSD",
            description: "seagate_3_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-3-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/nas-drives/ironwolf-ssd/",
          },
        ],
      },
      {
        title: "seagateButton_3",
        pageLink:
          "https://www.seagate.com/kr/ko/products/video-analytics/surveillance/",
        itemList: [
          {
            name: "SkyHawk",
            description: "seagate_4_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-4-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/surveillance-drives/skyhawk-hard-drive/",
          },
          {
            name: " ",
            description: "seagate_4_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-4-2.jpg')" }}
              />
            ),
            itemLink: "",
          },
          {
            name: "SkyHawk AI",
            description: "seagate_4_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-4-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/surveillance-drives/skyhawk-hard-drive/",
          },
        ],
      },
      {
        title: "PC Upgrade",
        pageLink: "https://www.seagate.com/kr/ko/products/hard-drives/",
        itemList: [
          {
            name: "BarraCuda",
            description: "seagate_5_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-5-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/hard-drives/barracuda-hard-drive/",
          },
          {
            name: " ",
            description: "seagate_5_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-5-2.jpg')" }}
              />
            ),
            itemLink: "",
          },
          {
            name: "FireCuda",
            description: "seagate_5_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-5-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/gaming-drives/pc-gaming/firecuda-530-ssd/",
          },
        ],
      },
      {
        title: "seagate_2_title",
        pageLink:
          "https://www.seagate.com/kr/ko/products/external-hard-drives/",
        itemList: [
          {
            name: "One Touch HDD",
            description: "seagate_6_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-6-1.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/external-hard-drives/one-touch-external-drives/",
          },
          {
            name: "One Touch Hub",
            description: "seagate_6_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-6-2.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/external-hard-drives/one-touch-external-drives/",
          },
          {
            name: "FireCuda Gaming HDD",
            description: "seagate_6_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-6-3.png')" }}
              />
            ),
            itemLink:
              "https://www.seagate.com/kr/ko/products/gaming-drives/pc-gaming/firecuda-gaming-external-hard-drives/",
          },
        ],
      },
      {
        title: "LaCie",
        pageLink: "https://www.lacie.com/as/en/",
        itemList: [
          {
            name: "Rugged Mobile Storage",
            description: "seagate_7_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-7-1.png')" }}
              />
            ),
            itemLink: "https://www.lacie.com/as/en/products/rugged/",
          },
          {
            name: "d2 Professional",
            description: "seagate_7_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-7-2.png')" }}
              />
            ),
            itemLink: "https://www.lacie.com/as/en/products/d2/",
          },
          {
            name: "1big Dock / 2 Big Dock",
            description: "seagate_7_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/seagate-7-3.png')" }}
              />
            ),
            itemLink: "https://www.lacie.com/as/en/products/big/2big/",
          },
        ],
      },
    ],
  },
  // {
  //   head: {
  //     title: "BenQ",
  //     color: {
  //       main: "#482A91",
  //       hover: "#4b20ba",
  //       active: "#2f166c",
  //       background: "#f9f8fe",
  //     },
  //     mainLogo: <img alt="seagate-logo" src="/icons/brand-benq2.png"></img>,
  //     type: "benq",
  //     homeLink: "https://www.benq.com/ko-kr/business/index.html",
  //     description: ["benqDescription", "benqDescription1", "benqDescription2"],
  //   },
  //   item: [
  //     {
  //       title: "benq_1_title",
  //       pageLink: "https://www.benq.com/ko-kr/business/index.html",
  //       itemList: [
  //         {
  //           name: "benq_1_con_1",
  //           description: "benq_1_con_1_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundSize: "90%",
  //                 backgroundImage: "url('/icons/benq-1-1.jpg')",
  //               }}
  //             />
  //           ),
  //           itemLink: "https://www.benq.com/ko-kr/business/ifp/corporate.html",
  //         },
  //         {
  //           name: "benq_1_con_2",
  //           description: "benq_1_con_2_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundSize: "90%",
  //                 backgroundImage: "url('/icons/benq-1-2.jpg')",
  //               }}
  //             />
  //           ),
  //           itemLink: "https://www.benq.com/ko-kr/business/ifp/education.html",
  //         },
  //         {
  //           name: "benq_1_con_3",
  //           description: "benq_1_con_2_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundSize: "90%",
  //                 backgroundImage: "url('/icons/benq-1-3.png')",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://g5-prod.benq.com/ko-kr/business/ifp/accessory.html?_gl=1*4o5tbe*_ga*NzMyNzQ0ODk0LjE2OTA4NzExNzY.*_ga_Z1KB6B42JW*MTY5MDg3MTI2Mi4xLjEuMTY5MDg3MTI4My4zOS4wLjA.",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    head: {
      title: "QNAP",
      color: {
        main: "#2d3a7e",
        hover: "#4454a3",
        active: "#172469",
        background: "#f8f9fd",
      },
      mainLogo: <Icons.BrandQnap style={{ margin: "15px 0" }} />,
      type: "qnap",
      homeLink: "https://www.qnap.com/ko-kr/",
      description: ["qnapDescription", "qnapDescription1", "qnapDescription2"],
    },
    item: [
      {
        title: "Line-Up",
        pageLink: "https://www.qnap.com/ko-kr/",
        itemList: [
          {
            name: "Storage",
            description: "qnap_1_con_1_des",
            itemImg: (
              <div
                style={{
                  backgroundSize: "100%",
                  backgroundImage: "url('/icons/qnap-1-1.png')",
                }}
              />
            ),
            itemLink: "https://www.qnap.com/ko-kr/product",
          },
          {
            name: "",
            itemImg: (
              <Icons.BrandQnapGray
                style={{ aspectRatio: 1, width: "100%", height: "100%" }}
              />
            ),
            itemLink: "",
          },
          {
            name: "Networking",
            description: "qnap_1_con_3_des",
            itemImg: (
              <div
                style={{
                  backgroundSize: "100%",
                  backgroundImage: "url('/icons/qnap-1-2.png')",
                }}
              />
            ),
            itemLink: "https://www.qnap.com/ko-kr/product/compare-switches",
          },
          {
            name: "Accessories",
            description: "qnap_2_con_1_des",
            itemImg: (
              <div
                style={{
                  backgroundSize: "95%",
                  backgroundImage: "url('/icons/qnap-1-3.png')",
                }}
              />
            ),
            itemLink: "https://www.qnap.com/ko-kr/product/compare-accessory",
          },
          {
            name: "",
            itemImg: (
              <Icons.BrandQnapGray
                style={{ aspectRatio: 1, width: "100%", height: "100%" }}
              />
            ),
            itemLink: "",
          },
          {
            name: "QVR Pro Appliance",
            description: "qnap_2_con_3_des",
            itemImg: (
              <div
                style={{
                  backgroundSize: "100%",
                  backgroundImage: "url('/icons/qnap-1-4.png')",
                }}
              />
            ),
            itemLink:
              "https://qnap.com/ko-kr/product/compare-qvr-pro-appliance",
          },
        ],
      },
    ],
  },
  {
    head: {
      title: "ARISTA",
      color: {
        main: "#16325B",
        hover: "#184890",
        active: "#092348",
        background: "#f4f5f7",
      },
      mainLogo: <Icons.BrandArista2 style={{ margin: "15px 0" }} />,
      type: "arista",
      homeLink: "https://www.arista.com/ko/",
      brochure: [
        "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/%EC%95%84%EB%A6%AC%EC%8A%A4%ED%83%80/%EC%95%84%EB%A6%AC%EC%8A%A4%ED%83%80_%EC%96%91%EB%A9%B4%EC%B9%B4%EB%8B%A4%EB%A1%9C%EA%B7%B8(12P)_A4_500%EB%B6%80.pdf",
      ],
      description: [
        "aristaDescription",
        "aristaDescription1",
        "aristaDescription2",
      ],
    },
    item: [
      {
        title: "arista_1_title",
        pageLink: "https://www.arista.com/ko/",
        itemList: [
          {
            name: "arista_1_con_1",
            description: "arista_1_con_1_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-1.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/platforms",
          },
          {
            name: "arista_1_con_2",
            description: "arista_1_con_2_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-2.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/platforms",
          },
          {
            name: "arista_1_con_3",
            description: "arista_1_con_3_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-3.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/platforms",
          },
          {
            name: "arista_1_con_4",
            description: "arista_1_con_4_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-4.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/platforms",
          },
          {
            name: "arista_1_con_5",
            description: "arista_1_con_5_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-5.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/platforms",
          },
          {
            name: "arista_1_con_6",
            // name: "7130 Series",
            description: "arista_1_con_6_des",
            itemImg: (
              <div
                style={{ backgroundImage: "url('/icons/arista-1-6.png')" }}
              />
            ),
            itemLink: "https://www.arista.com/ko/products/eos/eos-cloudvision",
          },
        ],
      },
    ],
  },
  // {
  //   head: {
  //     title: "tp-link",
  //     color: {
  //       main: "#4ACBD6",
  //       hover: "#81dbe3",
  //       active: "#2fa4ae",
  //       background: "#f0f7f8",
  //     },
  //     mainLogo: <Icons.BrandTpLink style={{ height: "59px" }} />,
  //     type: "tplink",
  //     homeLink: "https://www.tp-link.com/kr/",
  //     description: [
  //       "tplinkDescription",
  //       "tplinkDescription1",
  //       "tplinkDescription2",
  //     ],
  //   },
  //   item: [
  //     {
  //       title: "tplink_1_title",
  //       pageLink: "https://www.tp-link.com/kr/business-networking/",
  //       itemList: [
  //         {
  //           name: "tplink_1_con_1",
  //           description: "tplink_1_con_1_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-1-1.png')",
  //                 backgroundSize: "90%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/omada-wifi-outdoor/eap610-outdoor/",
  //         },
  //         {
  //           name: "tplink_1_con_2",
  //           description: "tplink_1_con_2_des",
  //           itemImg: (
  //             <div
  //               style={{ backgroundImage: "url('/icons/tplink-1-2.png')" }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/omada-wifi-ceiling-mount/eap670/",
  //         },
  //         {
  //           name: "tplink_1_con_3",
  //           description: "tplink_1_con_3_des",
  //           itemImg: (
  //             <div
  //               style={{ backgroundImage: "url('/icons/tplink-1-3.png')" }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/omada-router-wired-router/er8411/",
  //         },
  //       ],
  //     },
  //     {
  //       title: "tplink_2_title",
  //       pageLink:
  //         "https://www.tp-link.com/kr/business-networking/omada/surveillance/",
  //       itemList: [
  //         {
  //           name: "tplink_2_con_1",
  //           description: "tplink_2_con_1_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-1.png')",
  //                 backgroundSize: "80%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/home-networking/cloud-camera/tapo-c220/",
  //         },
  //         {
  //           name: "tplink_2_con_2",
  //           description: "tplink_2_con_2_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-2.png')",
  //                 backgroundSize: "80%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/home-networking/cloud-camera/tapo-c110/",
  //         },
  //         {
  //           name: "tplink_2_con_3",
  //           description: "tplink_2_con_3_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-3.png')",
  //                 backgroundSize: "80%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/home-networking/cloud-camera/tapo-c425/",
  //         },
  //         {
  //           name: "tplink_2_con_4",
  //           description: "tplink_2_con_4_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-4.png')",
  //                 backgroundSize: "70%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/vigi-network-camera/vigi-c540v/",
  //         },
  //         {
  //           name: "tplink_2_con_5",
  //           description: "tplink_2_con_5_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-5.png')",
  //                 backgroundSize: "75%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/vigi-network-video-recorder/vigi-nvr1104h-4p/",
  //         },
  //         {
  //           name: "tplink_2_con_6",
  //           description: "tplink_2_con_6_des",
  //           itemImg: (
  //             <div
  //               style={{
  //                 backgroundImage: "url('/icons/tplink-2-6.png')",
  //                 backgroundSize: "95%",
  //               }}
  //             />
  //           ),
  //           itemLink:
  //             "https://www.tp-link.com/kr/business-networking/vigi-network-video-recorder/vigi-nvr2016h/",
  //         },
  //       ],
  //     },
  //   ],
  // },
];
const seagateData = [
  "A/S절차안내",
  "A/S센터안내",
  "A/S규정",
  "택배발송 시 주의사항",
  "자주하는 질문",
];
const seagateProcess = [
  {
    title: `STEP 01. 직접 방문`,
    description: `센터운영시간: 평일10시 ~ 17시 (점심시간 12시 ~ 13시) / 주말, 공휴일 휴무`,
  },
  {
    title: `STEP 02. 서비스 의뢰 접수`,
    description: `서비스 의뢰 접수는 방문 순차적으로 접수`,
  },
  {
    title: `STEP 03. 점검 및 A/S`,
    description: `제품 접수 / 외간 검사후 TEST 및 A/S처리`,
  },
  { title: `STEP 04. 제품출고`, description: `서비스 완료 후 제품 출고` },
];
const seagateProcess2 = [
  {
    title: `STEP 01. HDD 및 SSD 하드분리`,
    description: `PC본체에서 A/S 받을 제품 분리`,
  },
  {
    title: `STEP 02. 고객 정보 및 증상 메모`,
    description: `고객정보: 성함, 연락처, 받으실주소, 고장증상 메모 후 동봉`,
  },
  {
    title: `STEP 03. 택배 포장 및 발송`,
    description: `택배 포장 후 선불택배(타택배사 가능) 통하여 제품 발송 *택배 주의사항 참고 바랍니다*`,
  },
  {
    title: `STEP 04. 제품 입고 확인`,
    description: ` `,
  },
  {
    title: `STEP 05. 검사 및 A/S`,
    description: `제품 외관 검사 후 TEST 및 A/S처리`,
  },
  {
    title: `STEP 06. 제품출고`,
    description: `서비스 완료 후 제품 출고`,
  },
];
const seagateProcess3 = [
  {
    name: `Desktop 3.5"`,
    contents: [
      {
        title: "BarraCuda",
        warranty: "제한적 2년 보증제품 (국내 보증 2년후 보증 종료)",
        comment: " ",
      },
    ],
  },
  {
    name: `Notebook 2.5"`,
    contents: [
      {
        title: "BarraCuda",
        warranty: "제한적 2년 보증제품 (국내 보증 2년후 보증 종료)",
        comment: " ",
      },
      {
        title: "BarraCuda PRO",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: " ",
      },
    ],
  },
  {
    name: `Consumer Electronics`,
    contents: [
      {
        title: "Skyhawk",
        warranty:
          "제한적3년 보증제품 [국내 보증 2년(1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스 (1TB 제외)",
      },
      {
        title: "Skyhawk AI",
        warranty:
          "제한적5년 보증제품 [국내 보증 3년(1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "Ironwolf",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년(1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "ironwolf Pro",
        warranty:
          "제한적5년 보증제품 [국내 보증 3년(1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `Enterprise`,
    contents: [
      {
        title: "Exos",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: " ",
      },
    ],
  },
  {
    name: `SSD 2.5"`,
    contents: [
      {
        title: "Nytro",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: " ",
      },
      {
        title: "Ironwolf 125",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `SSD M.2 NVMe`,
    contents: [
      {
        title: "Game Drive PS5",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: " ",
      },
      {
        title: "Ironwolf 525",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "FireCuda 520",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "FireCuda 530",
        warranty:
          "제한적5년 보증제품 [국내 보증 2년 (1:1교환 또는 제조사 RMA) 이후 제조사 RMA진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `외장형 HDD 2.5"`,
    contents: [
      {
        title: "Expansion",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "Game Drive for PS4",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: `OneTouch 2.5"`,
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "Ultra Touch USB-C",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "FireCuda Gaming HDD",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `외장형 HDD 3.5"`,
    contents: [
      {
        title: "One Touch Hub",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "FireCuda Gaming Hub",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `외장형 SSD`,
    contents: [
      {
        title: "FAST One Touch SSD",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "FireCuda Gaming SSD",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
  {
    name: `LaCie`,
    contents: [
      {
        title: "Rugged Type C USB3.1",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: `2TB Rugged SECURE USB 3.1-C`,
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "RUGGED RAID SHUTTLE USB 3.1-C",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "LaCie Mobile Drive USB-C Silver V2",
        warranty:
          "제한적 3년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "3년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "d2 Professional",
        warranty:
          "제한적 5년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "5년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "1big Dock Thunderbolt 3",
        warranty:
          "제한적 5년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "5년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "2BIG DOCK V2",
        warranty:
          "제한적 5년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "5년 Rescue 무상 데이터복구서비스",
      },
      {
        title: "2BIG RAID USB-C Black",
        warranty:
          "제한적 5년 보증 제품 [국내 보증 2년 (1:1 교환 또는 제조사 RMA) 이후 제조사 RMA 진행]",
        comment: "5년 Rescue 무상 데이터복구서비스",
      },
    ],
  },
];
const seagateProcess4 = [
  { number: "1", data: "구입 일자 기준으로 보증 기간 산정" },
  {
    number: "2",
    data: (
      <>
        구입 일자는 구매 영수증 중 가장 최근 일자 기준으로 산정
        <b style={{ color: "red" }}>(간이 영수증 불가)</b>
      </>
    ),
  },
  {
    number: "3",
    data: "구매영수증 없는 경우 S/N조회 에스씨지솔루션즈에서 유통된 날짜로 진행됨. 구매날짜와 상의 할경우 구매영수증 첨부",
  },
  {
    number: "4",
    data: "무상 보증 기간 이더라도 외관상 파손/훼손 등이 발생한 경우 유상 또는 서비스 불가(하단 보증기간 내 유상 또는 서비스불가 경우 참조)",
  },
  {
    number: "5",
    data: "국내 보증 기간 내라도 제조사 사정에 따라 RMA 진행이 될 수 있음.",
  },
  {
    number: "6",
    data: "기존 제품 보다 보증 기간이 단축되는 제품으로 교체 받는 경우 씨게이트 통보하여 소급 적용.",
  },
  {
    number: "7",
    data: (
      <b style={{ color: "red" }}>
        단종 제품의 경우 씨게이트사 RMA 정책에 따라 제품변경이 될 수 있음.
      </b>
    ),
  },
  {
    number: "8",
    data: (
      <b style={{ color: "red" }}>
        저장된 데이터에 대해서는 보증하지 않으므로 정기적으로 백업해 두셔야
        합니다. 판매처나 A/S센터에서는 책임을 지지 않습니다.
      </b>
    ),
  },
  {
    number: "9",
    data: (
      <b style={{ color: "red" }}>
        접수된 불량 제품은 어떠한 경우에도 반환이 불가 합니다. 신중히 선택후
        A/S접수 진행 바랍니다.
      </b>
    ),
  },
];
const seagateProcess5 = [
  {
    case: "구입 후 10일 이내 정상적인 사용 상태에서 발생한 성능, 기능상의 하자 발생",
    solve: "제품 교환 또는 환급",
  },
  {
    case: "구입 후 1개월 이내 정상적인 사용 상태에서 발생한 성능, 기능상의 하자 발생",
    solve: "제품 교환",
  },
  {
    case: "구입 후 1년 이내 동일한 하자로 3회 고장 발생",
    solve: "제품 교환 또는 환급",
  },
];

const BusinessItSolutionStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    height: unset !important;
    position: relative;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BusinessItSolutionMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/business-solution.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 60px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessItSolutionMain = () => {
  return (
    <BusinessItSolutionMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            IT Solution
          </motion.h1>
        </motion.div>
      </div>
    </BusinessItSolutionMainStyle>
  );
};

const BusinessItMainStyle = styled.div<dataProps>`
  .scroll {
    max-width: 1200px;
    width: 95%;
    margin: 30px auto;
  }
  .nav-header {
    height: 60px;
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.main};
    text-align: center;
    transition: 0.1s;
    .main-nav {
      line-height: 1.1em;
      height: 100%;
      display: flex;
      border-bottom: 2px solid ${(props) => props.main};
      transition: inherit;
      li {
        transition: 0.5s;
        width: 100%;
        height: 100%;
        display: flex;
        p {
          margin: auto;
        }
        &.active {
          background-color: ${(props) => props.main};
          color: #fff;
        }
        :hover {
          color: #fff;
          background-color: ${(props) => props.active};
        }
      }
    }
  }
  .main-contents {
    text-align: center;
    .main-contents-header {
      padding: 30px 0;
      background-color: #f4f4f4;
      display: flex;
      flex-direction: column;
      .main-contents-logo {
        height: 70px;
        width: auto;
        img {
          max-height: 70px;
          max-width: 200px;
          transition: 0.1s;
          font-size: 0;
        }
      }
      h1 {
        margin-top: 20px;
        font-size: 30px;
      }
      .main-button-box {
        display: flex;
        justify-content: center;
        button {
          display: flex;
          width: 150px;
          height: 40px;
          margin-top: 20px;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          margin-right: 10px;
          :nth-of-type(2) {
            background-color: ${(props) => props.main};
            color: #fff;
          }
          :nth-of-type(3) {
            margin-right: 0px;
          }
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
          }
          :first-child::after {
            background-color: ${(props) => props.main};
          }
          :nth-of-type(2)::after {
            background-color: ${(props) => props.active};
          }
          :nth-of-type(3)::after {
            background-color: ${(props) => props.active};
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            transition: inherit;
            margin: auto;
            font-size: 14px;
            z-index: 1;
          }
        }
        .brochure {
          background-color: ${(props) => props.active} !important;
          color: #fff;
          :hover::after {
            background-color: ${(props) => props.main};
          }
        }
      }
    }
    .main-contents-body {
      margin: 50px 0;
      h1 {
        font-size: 30px;
        color: ${(props) => props.main};
        display: flex;
        border-bottom: 1px solid ${(props) => props.main};
        ::before {
          /* 구분자 숨김 240809 */
          /* content: ""; */
          width: 6px;
          height: 22px;
          margin: auto 7px auto 0;
          background-color: ${(props) => props.main};
        }
        aside {
          margin: auto 5px auto auto;
          padding: 5px 10px;
          font-size: 14px;
          font-weight: 400;
          border: 1px solid ${(props) => props.main};
          transition: 0.3s;
          position: relative;
          ::after {
            transition: inherit;
            content: "";
            position: absolute;
            width: 0%;
            height: 100%;
            background-color: ${(props) => props.main};
            top: 0;
            left: 0;
            z-index: 0;
          }
          :hover {
            ::after {
              width: 100%;
            }
            p {
              color: #fff;
            }
          }
          p {
            position: relative;
            transition: inherit;
            margin: auto;
            z-index: 1;
          }
        }
      }
      > ul {
        display: flex;
        flex-wrap: wrap;
        margin: auto;
        max-width: 1000px;
        padding: 20px 0;
        .main-contents-item {
          width: calc(100% / 3);
          display: flex;
          flex-direction: column;
          padding: 20px;
          transition: 0.3s;
          justify-content: space-between;
          position: relative;
          :hover {
            transform: translate(-2px, -2px);
            box-shadow: 2px 2px 5px 0 #00000022;
          }
          h2 {
            font-size: 22px;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            padding: 10px 0;
            margin-bottom: auto;
          }
          .contents-img div {
            aspect-ratio: 1;
            background-color: ${(props) => props.background};
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      .seagate-process {
        h1 {
          margin-bottom: 20px;
        }
        .seagate-process-ul {
          text-align: left;
          width: 100%;
          max-width: 800px;
          margin: 20px auto;
          .seagate-process-li {
            h3 {
              display: flex;
              font-size: 18px;
              ::before {
                content: "";
                width: 4px;
                height: 18px;
                margin: auto 5px auto 0;
                background: ${(props) => props.main};
              }
            }
            p {
              font-size: 16px;
              margin-left: 10px;
            }
            & + li {
              margin-top: 10px;
            }
          }
        }
      }
      .as-center {
        .as-center-title {
          text-align: left;
          padding: 20px 10px;
          background: #eee;
          margin: 20px 0;
        }
        .as-center-box {
          display: flex;
          border-bottom: 2px solid #fff;
          &:last-of-type(1) {
            border-bottom: unset;
          }
          h2 {
            min-width: 150px;
            background: ${(props) => props.main};
            color: #fff;
            min-height: 50px;
            display: flex;
            span {
              margin: auto;
            }
          }
          p {
            width: 100%;
            text-align: left;
            padding: 0 30px;
            display: flex;
            span {
              width: 100%;
              margin: auto 0;
            }
          }
        }
      }
      .seagate-policy {
        max-width: 1200px;
        width: 95%;
        .seagate-policy-box {
          width: 100%;
          margin: 100px auto;
          color: #666;
          .seagate-policy-head {
            text-align: center;
            display: flex;
            margin: auto;
            width: 100%;
            padding: 10px 0;
            background: ${(props) => props.main};
            font-weight: bold;
            color: #fff;
            .seagate-policy-division {
              min-width: 150px;
            }
            h3 {
              display: flex;
              width: 100%;
              .seagate-policy-item {
                width: 200px;
                min-width: 120px;
              }
              .seagate-policy-warranty {
                width: 100%;
                min-width: 200px;
              }
              .seagate-policy-comment {
                width: 400px;
                min-width: 300px;
              }
            }
          }
          .seagate-policy-list {
            font-size: 18px;
            display: flex;
            margin: auto;
            width: 100%;
            transition: 0.3s;
            word-break: keep-all;
            :hover {
              background-color: #f4f4f4;
            }
            .seagate-policy-category {
              min-width: 150px;
              display: flex;
              border-bottom: 1px solid #eee;
              h2 {
                margin: auto;
              }
            }
            .seagate-policy-contents {
              display: flex;
              flex-direction: column;
              width: 1150px;
              .seagate-policy-item {
                width: 200px;
                min-width: 120px;
                display: flex;
                justify-content: center;
                span {
                  margin: auto;
                }
              }
              .seagate-policy-warranty {
                text-align: left;
                width: 100%;
                min-width: 200px;
                padding: 10px;
              }
              .seagate-policy-comment {
                width: 400px;
                min-width: 300px;
                display: flex;
                p {
                  margin: auto;
                }
              }
            }
            li {
              display: flex;
              min-height: 80px;
              & > div {
                padding: 10px 0;
                border-bottom: 1px solid #eee;
              }
            }
          }
        }
        .seagate-note {
          .seagate-note-title {
            margin: auto;
          }
          .seagate-note-list {
            font-size: 18px;
            transition: 0.3s;
            word-break: keep-all;
            text-align: left;
            min-height: 40px;
            display: flex;
            border-bottom: 1px solid #eee;
            :hover {
              background-color: #f4f4f4;
            }
            span {
              margin: auto 10px;
              display: flex;
              ::before {
                content: "";
                width: 4px;
                height: 18px;
                margin: auto 5px auto 0;
                background: ${(props) => props.main};
              }
            }
          }
        }
        .seagate-regulations {
          .seagate-regulations-head {
            margin-top: 20px;
            .seagate-regulations-title {
              text-align: center;
            }
          }
          .seagate-regulations-title {
            margin: auto 0;
            width: 100%;
            text-align: left;
          }
          .seagate-regulations-compensation {
            margin: auto 0;
            min-width: 200px;
          }
          .seagate-regulations-list {
            display: flex;
            min-height: 50px;
            transition: 0.3s;
            border-bottom: 1px solid #eee;
            :hover {
              background-color: #f4f4f4;
            }
          }
        }
      }
      .post-notice {
      }
      .seagate-qna {
        .button-group {
          margin-top: 10px;
          display: flex;
          a + a {
            margin-left: 10px;
          }
          .download {
            display: flex;
            width: 300px;
            height: 40px;
            border: 1px solid ${(props) => props.main};
            transition: 0.3s;
            position: relative;
            background-color: unset;
            ::after {
              transition: inherit;
              content: "";
              position: absolute;
              width: 0%;
              height: 100%;
              background-color: ${(props) => props.main};
              top: 0;
              left: 0;
              z-index: 0;
            }
            :hover {
              ::after {
                width: 100%;
              }
              p {
                color: #fff;
              }
            }
            p {
              transition: inherit;
              margin: auto;
              z-index: 1;
            }
          }
        }
      }
      .seagate-textbox {
        padding: 20px;
        text-align: left;
        .textbox-title {
          span {
            font-weight: normal;
            font-size: 18px;
          }
          & + .textbox-title {
            margin-top: 10px;
          }
        }
        .textbox-ul {
          padding: 10px;
          li {
            display: flex;
            transition: 0.3s;
            min-height: 40px;
            align-items: center;
            :hover {
              background-color: #f4f4f4;
            }
            ::before {
              content: "";
              min-width: 6px;
              height: 22px;
              margin: 10px 7px auto;
              background-color: ${(props) => props.main};
            }
          }
        }
        .textbox-notice {
          background: #eee;
          padding: 10px 20px;
          width: fit-content;
        }
      }
      .bg-01 {
        background: #eee;
      }
      .text-red {
        color: #f00;
      }
      .mt10 {
        margin-top: 10px;
      }
      .mb30 {
        margin-bottom: 30px;
      }
      .text-align-left {
        text-align: left;
      }
      .article-title {
        padding: 10px 30px;
        margin-top: 50px;
        background-color: ${(props) => props.main};
        color: #fff;
        width: fit-content;
      }
    }
  }
  a {
    z-index: 1;
  }

  @media (max-width: 768px) {
    .nav-header {
      font-size: 14px;
      height: 40px;
      line-height: 16px;
    }
    .main-contents {
      .main-contents-header {
        h1 {
          word-break: keep-all;
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
      .main-contents-body {
        h1 {
          font-size: 20px;
          aside {
            padding: 1px 5px;
          }
        }
        > ul {
          .main-contents-item {
            width: calc(100%);
            max-width: 400px;
            margin: auto;
            display: flex;
            flex-direction: column;
            padding: 20px;
            transition: 0.3s;
            justify-content: space-between;
            :hover {
              transform: translate(-2px, -2px);
              box-shadow: 2px 2px 5px 0 #00000022;
            }
            h2 {
              font-size: 18px;
              margin-bottom: 20px;
            }
            p {
              font-size: 16px;
              padding: 10px 0;
              margin-bottom: auto;
            }
            .contents-img div {
              aspect-ratio: 1;
              background-color: ${(props) => props.background};
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
            }
          }
        }
        .as-center {
          .as-center-box {
            display: unset;
            &:nth-of-type(1) {
              border-bottom: 2px solid #fff;
            }
            h2 {
              min-width: 150px;
              background: ${(props) => props.main};
              color: #fff;
              min-height: 50px;
              display: flex;
            }
            p {
              min-height: 50px;
              padding: 0px 5px;
            }
          }
        }
        .seagate-policy {
          width: 100%;
          .seagate-policy-box {
            margin: 50px auto;
            .seagate-policy-head {
              flex-wrap: wrap;
              .seagate-policy-division {
                min-width: 80px;
                width: 100%;
                height: 30px;
              }
              h3 {
                display: flex;
                width: 100%;
                .seagate-policy-item {
                  width: 10%;
                  min-width: 100px;
                }
                .seagate-policy-warranty {
                  width: 80%;
                  min-width: 100px;
                }
                .seagate-policy-comment {
                  width: 10%;
                  min-width: 100px;
                }
              }
            }
            .seagate-policy-list {
              font-size: 14px;
              flex-wrap: wrap;
              :hover {
                background-color: #f4f4f4;
              }
              .seagate-policy-category {
                min-width: 80px;
                width: 100%;
                height: 30px;
                display: flex;
                border-bottom: 1px solid #eee;
                h2 {
                  margin: auto;
                }
              }
              .seagate-policy-contents {
                display: flex;
                flex-direction: column;
                width: 1150px;
                .seagate-policy-item {
                  width: 10%;
                  min-width: 100px;
                  display: flex;
                  justify-content: center;
                  span {
                    margin: auto;
                  }
                }
                .seagate-policy-warranty {
                  text-align: left;
                  width: 80%;
                  min-width: 100px;
                }
                .seagate-policy-comment {
                  width: 100px;
                  min-width: 100px;
                  max-width: 200px;
                  display: flex;
                  p {
                    margin: auto;
                  }
                }
              }
              li {
                display: flex;
                min-height: 80px;
                & > div {
                  padding: 10px 0;
                  border-bottom: 1px solid #eee;
                  word-break: break-all;
                }
              }
            }
          }
          .seagate-note {
            .seagate-note-list {
              font-size: 14px;
              span {
                ::before {
                  min-width: 4px;
                }
              }
            }
          }
          .seagate-regulations {
            .seagate-regulations-head {
              flex-wrap: nowrap;
            }
            .seagate-regulations-title {
            }
            .seagate-regulations-compensation {
              margin: auto 0;
              min-width: 100px;
            }
            .seagate-regulations-list {
              display: flex;
              min-height: 50px;
              transition: 0.3s;
              border-bottom: 1px solid #eee;
              :hover {
                background-color: #f4f4f4;
              }
            }
          }
        }
        .seagate-textbox {
          padding: 10px;
          .textbox-title {
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
`;
const BusinessItMain = ({
  t,
  i18n,
  type,
  setIsPopup,
  setType,
  location,
  up1,
  up2,
}: any) => {
  const [tab, setTab] = useState(Number);
  const [isAsNotice, setIsAsNotice] = useState(
    location.state?.delivery !== undefined ? location.state.delivery : 0
  );
  useEffect(() => {
    if (type === "dell") {
      setTab(0);
    } else if (type === "seagate") {
      setTab(1);
    } else if (type === "qnap") {
      setTab(2);
    } else if (type === "arista") {
      setTab(3);
    }
    //  else if (type === "tplink") {
    //   setTab(4);
    // }
  }, []);

  useEffect(() => {
    location.state?.delivery === undefined
      ? scrollToRef(up1, location)
      : setTimeout(() => {
          scrollToRef(up2, location);
        }, 200);
  }, [location]);
  return (
    <BusinessItMainStyle
      main={data[tab].head.color.main}
      active={data[tab].head.color.active}
      hover={data[tab].head.color.hover}
      background={data[tab].head.color.background}
    >
      <div className="scroll" ref={up1}>
        <header className="nav-header">
          <ul className="main-nav pointer">
            {data.map((res: any, idx: Number) => {
              return (
                <li
                  key={String(idx)}
                  style={{ position: "relative" }}
                  className={`${tab === idx ? "active" : ""} tab${idx}`}
                  onClick={() => {
                    setTab(Number(idx));
                    setType(res.head.type);
                  }}
                >
                  <p>{res.head.title}</p>
                  <Link
                    style={{
                      top: "0",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                    }}
                    to={`/business/it/${res.head.type}`}
                    onClick={(e) => {
                      e.preventDefault();
                      window.history.pushState(null, "", res.head.type);
                    }}
                  ></Link>
                </li>
              );
            })}
          </ul>
        </header>
        <section className="main-contents">
          <article className="main-contents-header">
            <div className="main-contents-logo">{data[tab].head.mainLogo}</div>
            <h1>{t(data[tab].head.description[1])}</h1>
            <p>{t(data[tab].head.description[2])}</p>
            <span className="main-button-box">
              {i18n.language === "ko" && (
                <button
                  className="pointer"
                  onClick={() => {
                    tab !== 1 && tab !== 3 && setIsPopup(true);
                    tab === 1 &&
                      window.open("https://scgsseagate.co.kr/estimate");
                    tab === 3 &&
                      window.open(
                        "https://scgs.my.salesforce-sites.com/AristaRegister"
                      );
                  }}
                >
                  <p>{t("csButton_1")}</p>
                </button>
              )}
              <button className="pointer">
                <p>
                  {t("more")}
                  <Link target="_blank" to={data[tab].head.homeLink}></Link>
                </p>
              </button>
              {data[tab].head.type === "arista" ? (
                data[tab].head.brochure?.map((data, idx) => {
                  return (
                    <button key={idx} className="brochure pointer">
                      <p>
                        {t("arista_1_brochure")}
                        <a target="_blank" download href={data}></a>
                      </p>
                    </button>
                  );
                })
              ) : (
                <></>
              )}
            </span>
          </article>
          {data[tab].item.map((res, idx) => {
            return (
              <article className="main-contents-body" key={idx}>
                <h1>
                  <p>{t(res.title)}</p>
                  <aside>
                    <p>{t("more")}</p>
                    <Link target="_blank" to={res.pageLink}></Link>
                  </aside>
                </h1>
                <ul>
                  {res.itemList.map(
                    (
                      res: {
                        name: string;
                        itemImg: React.ReactNode;
                        itemLink?: string;
                      },
                      idx
                    ) => {
                      return (
                        <li key={idx} className="main-contents-item">
                          <h2>{t(res.name)}</h2>
                          <div className="contents-img">{res.itemImg}</div>
                          {res.itemLink && (
                            <a target="_blank" href={res.itemLink}></a>
                          )}
                        </li>
                      );
                    }
                  )}
                </ul>
              </article>
            );
          })}
          {tab === 1 && i18n.language === "ko" && (
            <>
              <header className="nav-header" ref={up2}>
                <ul className="main-nav pointer">
                  {seagateData.map((res, idx: Number) => {
                    return (
                      <li
                        key={String(idx)}
                        className={`${isAsNotice === idx ? "active" : ""}`}
                        onClick={() => {
                          setIsAsNotice(Number(idx));
                        }}
                      >
                        <p>{t(res)}</p>
                      </li>
                    );
                  })}
                </ul>
              </header>
              <article
                style={{ minHeight: "50vh" }}
                className="main-contents-body"
              >
                {isAsNotice === 0 && (
                  <article className="seagate-process">
                    <h2 className="article-title">내방 서비스 처리 절차</h2>
                    <Icons.SeagateAs1
                      style={{
                        margin: `50px 0`,
                        width: "100%",
                        height: "170px",
                      }}
                    />
                    <ul className="seagate-process-ul">
                      {seagateProcess.map((res, idx) => {
                        return (
                          <li key={idx} className="seagate-process-li">
                            <h3>{t(res.title)}</h3>
                            <p>
                              <span>{t(res.description)}</span>
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                    <h2 className="article-title">택배 서비스 처리 절차</h2>
                    <Icons.SeagateAs2
                      style={{
                        margin: `50px 0`,
                        height: "170px",
                        width: "100%",
                      }}
                    />
                    <ul className="seagate-process-ul">
                      {seagateProcess2.map((res, idx) => {
                        return (
                          <li key={idx} className="seagate-process-li">
                            <h3>{t(res.title)}</h3>
                            <p>
                              <span>{t(res.description)}</span>
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                )}
                {isAsNotice === 1 && (
                  <article className="seagate-map">
                    <h2 className="article-title">
                      서비스센터 방문 및 택배 A/S 안내
                    </h2>
                    <p style={{ textAlign: "left", padding: "10px" }}>
                      택배 수거 신청 후에는 택배 기사님이 방문하기 전에 제품을
                      박스 포장해 두셔야 합니다. A/S 신청시 고객님의 성함,
                      연락처, 주소 고장 증상 등을 메모하여 동봉해 주시기
                      바랍니다.
                    </p>
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21283.87508711554!2d126.97260236091275!3d37.534633271162676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca2068f8f09df%3A0xd93536ffb2b4e29a!2z7JeQ7Iqk7JSo7KeA7IaU66Oo7IWY7KaIKOyjvCkg7Jqp7IKw7KeA7KCQ!5e0!3m2!1sko!2skr!4v1685601877727!5m2!1sko!2skr"
                      width="100%"
                      height="400"
                      style={{ border: 0, marginTop: "50px" }}
                      allowFullScreen={true}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <div className="as-center">
                      <h2 className="as-center-title">
                        Seagate HDD, SSD A/S Center
                      </h2>
                      <div className="as-center-box">
                        <h2>
                          <span>운영시간</span>
                        </h2>
                        <p>
                          <span>
                            평일 (오전 10시 ~ 오후 5시 / 점심시간 : 오후 12시 ~
                            1시)
                          </span>
                        </p>
                      </div>
                      <div className="as-center-box">
                        <h2>
                          <span>주소</span>
                        </h2>
                        <p>
                          <span>
                            서울시 용산구 한강대로273 용산빌딩 4층
                            에스씨지솔루션즈
                          </span>
                        </p>
                      </div>
                      <div className="as-center-box">
                        <h2>
                          <span>연락처</span>
                        </h2>
                        <p>
                          <span>1811-7247 (내선 1번 → 1번)</span>
                        </p>
                      </div>
                    </div>
                  </article>
                )}
                {isAsNotice === 2 && (
                  <article className="seagate-policy">
                    <h2 className="article-title">품질보증기간</h2>
                    <p style={{ padding: "10px", textAlign: "left" }}>
                      택배 수거 신청 후에는 택배 기사님이 방문하기 전에 제품을
                      박스 포장해 두셔야 합니다. A/S 신청시 고객님의 성함,
                      연락처, 주소 고장 증상 등을 메모하여 동봉해 주시기
                      바랍니다.
                    </p>
                    <div className="seagate-policy-box">
                      <div className="seagate-policy-head">
                        <div className="seagate-policy-division">
                          <p>구분</p>
                        </div>
                        <h3>
                          <div className="seagate-policy-item">
                            <p>상품명</p>
                          </div>
                          <div className="seagate-policy-warranty">
                            <p>품질보증기간</p>
                          </div>
                          <div className="seagate-policy-comment">
                            <p>비고</p>
                          </div>
                        </h3>
                      </div>
                      {seagateProcess3.map((res, idx) => (
                        <div className="seagate-policy-list" key={idx}>
                          <div className="seagate-policy-category">
                            <h2>{t(res.name)}</h2>
                          </div>
                          <div className="seagate-policy-contents">
                            {res.contents.map((res2, idx2) => (
                              <li key={idx2}>
                                <div className="seagate-policy-item">
                                  <span>{t(res2.title)}</span>
                                </div>
                                <div className="seagate-policy-warranty">
                                  <span>{t(res2.warranty)}</span>
                                </div>
                                <div className="seagate-policy-comment">
                                  <p>{res2?.comment}</p>
                                </div>
                              </li>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="seagate-policy-box seagate-note">
                      <h2 className="seagate-policy-head">
                        <p className="seagate-note-title">기타 주의사항</p>
                      </h2>
                      <ul className="seagate-policy-contents">
                        {seagateProcess4.map((res, idx) => {
                          return (
                            <li key={idx}>
                              <div className="seagate-note-list">
                                <span>{res.data}</span>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="seagate-policy-box seagate-regulations">
                      <h1>
                        <p>소비자 피해 보상 규정</p>
                      </h1>
                      <h2 className="seagate-policy-head seagate-regulations-head">
                        <p className="seagate-regulations-title">
                          소비자 피해 유형
                        </p>
                        <p className="seagate-regulations-compensation">
                          보상 안내
                        </p>
                      </h2>
                      {seagateProcess5.map((res, idx) => {
                        return (
                          <div key={idx} className="seagate-regulations-list">
                            <p className="seagate-regulations-title">
                              {res.case}
                            </p>
                            <p className="seagate-regulations-compensation">
                              {res.solve}
                            </p>
                          </div>
                        );
                      })}
                      <h2 className="text-red text-align-left mt10 mb30">
                        수리서비스는 존재하지 않으며, 1:1 교환 또는 RMA 진행
                      </h2>
                      <div className="seagate-textbox bg-01">
                        <h3 className="textbox-title">
                          <p>보증 기간내 서비스 불가로 판정되는 경우</p>
                          <span>
                            (당사의 보증 규정은 제조사인 씨게이트의 보증 규정을
                            따르고 있습니다.)
                          </span>
                        </h3>
                        <h3 className="textbox-title">
                          <p>제품의 원형이 보존되지 않는 경우</p>
                          <span>
                            (당사의 보증 규정은 제조사인 씨게이트의 보증 규정을
                            따르고 있습니다.)
                          </span>
                        </h3>
                        <ul className="textbox-ul">
                          <li>표면 낙서/스크래치/찍힘</li>
                          <li>금속 부분에 낙서</li>
                          <li>바코드 훼손/삭제</li>
                          <li>라벨 훼손</li>
                          <li>제품 라벨과 총판 라벨 이외 임의 부착물 부착</li>
                          <li>외관 파손</li>
                          <li>핀 파손</li>
                          <li>기판 파손/교체/부품 탐(Brun)</li>
                          <li>나사홀에 나사 박힘</li>
                        </ul>
                        <p className="textbox-notice text-red">
                          위 예시 외에도 제품 원형이 보존되지 않다고 판단되는
                          경우 A/S불가
                        </p>
                        <p className="textbox-notice text-red">
                          보증 무효 점검표 확인하기
                          <span
                            style={{
                              position: "relative",
                              fontSize: "16px",
                              border: `1px solid`,

                              padding: "1px 5px",
                              marginLeft: "10px",
                            }}
                          >
                            상세보기
                            <Link
                              target="_blank"
                              to={
                                "https://www.seagate.com/kr/ko/support/warranty-and-replacements/void-warranty-checklist/"
                              }
                            ></Link>
                          </span>
                        </p>
                        <ul className="textbox-ul">
                          <li>
                            천재지변(홍수, 낙뢰 등)에 의한 침수 또는 제품이
                            파손된 경우
                          </li>
                          <li>
                            측면 S/N과 윗면 S/N이 일치하지 않는 경우 또는 측면
                            S/N 라벨이 제거되거나 훼손된 경우
                          </li>
                        </ul>
                      </div>
                      <h2 className="article-title">기타사항</h2>
                      <div className="seagate-textbox">
                        <p className="textbox-notice">
                          제품에 저장된 데이터에 대해서는 당사나 제조사에서
                          책임을 지지 않으므로 정기적으로 다른 저장매체에 백업해
                          두셔야 합니다. 제품에 대한 더 많은 정보를 원하시면
                          www.seagate.com에 접속하셔서 확인할 수 있습니다.
                        </p>
                        <h3
                          className="textbox-title"
                          style={{ margin: "10px 0" }}
                        >
                          <p>
                            제품에 이상이 발생되면 직접 방문이나 택배를 이용하여
                            A/S 받을 수 있습니다.
                          </p>
                          <span>
                            (* 당사는 택배 배송 중 파손에 대한 책임을 지지
                            않으므로 유상 서비스 또는 서비스 불가 처리 될 수
                            있으니, 주의하여서 완충재를 충분히 사용하여 박스
                            포장 하시고, 포장 박스 및 포장지 훼손에 대한 책임은
                            지지 않으며 되돌려 드리지 않습니다.)
                          </span>
                        </h3>
                        <ul className="textbox-ul">
                          <li>
                            방문 및 택배 A/S : 서울시 용산구 한강로대로273
                            용산빌딩 4층 에스씨지솔루션즈
                          </li>
                          <li>전화번호: 1811-7247</li>
                        </ul>
                        <p className="textbox-notice">
                          씨게이트 제조사 기술지원센터: 00308-321-0729
                          <br />
                          (수신자부담 통화료가 부가되지 않습니다)
                        </p>
                      </div>
                    </div>
                  </article>
                )}
                {isAsNotice === 3 && (
                  <article className="post-notice">
                    <h2 className="article-title">택배발송시 주의 사항</h2>
                    <div className="seagate-textbox">
                      <ul className="textbox-ul">
                        <li>
                          택배 서비스 운임 비용은 편도 비용만 지원해 드립니다.
                        </li>
                        <li>
                          보내실 때는 편하신 택배사를 이용하여 선불로 보내주시기
                          바랍니다.
                        </li>
                        <li>
                          택배 도착 여부 확인은 택배사로 문의 또는 송장 조회
                          하시기 바랍니다.
                        </li>
                        <li>
                          착불 경우 CJ대한통운 이외 타 택배사 도착 시 수취거부
                          되며, 착불 반송 조치 될 수 있습니다.
                        </li>
                      </ul>
                      <h3
                        style={{ margin: "20px 0" }}
                        className="textbox-title"
                      >
                        <p>
                          에스씨지솔루션즈 유통된 제품 경우 영수증 구매
                          날짜로부터 14일(2주) 이내 경우 왕복택배비 지원 해
                          드립니다. 제품과 구매 영수증 필히 동봉 바랍니다. (간이
                          영수증 불가능)
                        </p>
                        <span>
                          (단, CJ대한통운 착불만 가능, 타택배사 착불 불가능,
                          편의점 착불 불가능)
                        </span>
                      </h3>

                      <p className="textbox-notice">
                        접수방법 : CJ대한통운 1588-1255 또는 인터넷, 스마트폰
                        어플리케이션 통한 접수 / 방문접수 또는 인터넷으로 가능
                        <br />
                        기술지원센터 택배주소 : 서울시 용산구 한강대로273
                        용산빌딩 4층 / TEL : 1811-7247
                      </p>
                      <ul className="textbox-ul">
                        <li>
                          천재지변(홍수, 낙뢰 등)에 의한 침수 또는 제품이 파손된
                          경우
                        </li>
                        <li>
                          측면 S/N과 윗면 S/N이 일치하지 않는 경우 또는 측면 S/N
                          라벨이 제거되거나 훼손된 경우
                        </li>
                      </ul>
                    </div>
                    <h2 className="article-title">반송사유</h2>
                    <div className="seagate-textbox">
                      <ul className="textbox-ul">
                        <li>
                          택배송장 보내는분 개인정보인해서 성함,연락처 확인이
                          안되는 경우 (연락처가 없으면 고객님에게 택배 발송이
                          어렵습니다)
                        </li>
                        <li>
                          착불 경우 CJ대한통운 이외 타 택배사 도착 시 수취거부
                          되며, 착불 반송 조치 될 수 있습니다.
                        </li>
                        <li>
                          시게이트 제품 이 아닌 다른 제품 타회사 제품 A/S부분과
                          관계 없는 제품 반송 될수 있습니다.
                        </li>
                      </ul>
                    </div>
                  </article>
                )}
                {isAsNotice === 4 && (
                  <article className="seagate-qna">
                    <h2 className="article-title">
                      Q. Seagate 제품을 테스트 해보고 싶습니다.
                    </h2>
                    <div className="seagate-textbox">
                      <p className="textbox-notice">
                        안녕하세요. 에스씨지솔루션즈 기술지원센터 입니다.
                        <br />
                        Seagate 제품의 불량 여부 확인을 위해 Test Tool은 공식
                        Seatools를 이용하여 주시면 되겠습니다.
                      </p>
                      <div className="button-group">
                        <a
                          target="_blank"
                          href={
                            "https://www.seagate.com/kr/ko/support/downloads/seatools/#http://www.seagate.com/kr/ko/support/downloads/item/seatools-win-master/"
                          }
                          className="download pointer"
                        >
                          <p>Windows Guide</p>
                        </a>
                        <a
                          target={"_blank"}
                          href={
                            "https://scgs-img.s3.ap-northeast-2.amazonaws.com/download/SeaTools-for-windows-ko-kr.pdf"
                          }
                          className="download pointer"
                        >
                          <p>SeaTools for Windows Guide</p>
                        </a>
                      </div>
                    </div>
                    <h2 className="article-title">
                      Q. Seagate 제품 내장(베어드라이브) 하드를 구매했는데
                      인식이 안되요.
                    </h2>
                    <div className="seagate-textbox">
                      <ul className="textbox-ul">
                        <p>안녕하세요. 에스씨지솔루션즈 기술지원센터 입니다.</p>
                        <li>
                          제어판 - 관리도구 - 컴퓨터 관리 - 디스크 관리 메뉴에
                          선택
                        </li>
                        <li>
                          구매한 하드디스크 선택해서 초기화 (예. 디스크1일 경우
                          디스크1 글자에 마우스 대고 우측버튼 클릭 [디스크
                          초기화] 선택)
                        </li>
                        <li>초기화 할때 주의사항</li>
                        <ul
                          className="textbox-notice"
                          style={{ marginBottom: "10px" }}
                        >
                          <p>
                            MBR : 2테라 이하 제품에서 가능, windows 설치시 선택
                            필요
                          </p>
                          <p>
                            GPT : 2테라 이상 제품에서 선택, windows 설치
                            불가(4테라 제품을 MBR 선택하면 2테라만 사용가능,
                            나머지 용량 사용 불가)
                          </p>
                        </ul>
                        <li>
                          우측 칸 할당되지 않음에 마우스 대고 우측버튼 클릭 [새
                          단순볼륨] 선택
                        </li>
                        <ul
                          className="textbox-notice"
                          style={{ marginBottom: "10px" }}
                        >
                          <p>
                            단순볼륨 마법사 시작, 마침까지 진행 후 포맷이 완료될
                            때까지 기다리세요.
                          </p>
                        </ul>
                        <li>
                          포맷이 완료되면 윈도우 탐색기에서 드라이브 확인 가능
                        </li>
                      </ul>
                      <p
                        className="textbox-notice"
                        style={{ marginTop: "30px" }}
                      >
                        <b>내장하드를 외장으로 연결해서 포맷할때 주의사항</b>
                        <p>
                          컴퓨터 앞 USB포트나 연장선 또는 무전원 USB 허브를
                          사용하는 경우 포맷이 잘 안되거나 포맷이 되도 인식에
                          문제가 발생할 수 있습니다.
                        </p>
                        <p>
                          제품은 짧은 케이블로 컴퓨터 뒷면의 USB포트에 연결한
                          상태에서 포맷을 하셔야 됩니다.
                        </p>
                      </p>
                    </div>
                  </article>
                )}
              </article>
            </>
          )}
        </section>
      </div>
    </BusinessItMainStyle>
  );
};

const BusinessItSolution = ({ t, i18n, state }: any) => {
  const location = useLocation();
  const [isPopup, setIsPopup] = useState(false);
  const [type, setType] = useState(location.state?.type || state);
  const up1 = useRef(null);
  const up2 = useRef(null);
  return (
    <>
      <BusinessItSolutionStyle className="no-select">
        <BusinessItSolutionMain />
        <BusinessItMain
          t={t}
          i18n={i18n}
          type={type}
          setIsPopup={setIsPopup}
          setType={setType}
          location={location}
          up1={up1}
          up2={up2}
        />
      </BusinessItSolutionStyle>

      <AnimatePresence>
        {isPopup && (
          <Modal isPopup={isPopup} setIsPopup={setIsPopup} type={type}></Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default BusinessItSolution;
