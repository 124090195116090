import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Main from "./pages/Home";
import NotFound from "./pages/PageError";
import EDMPage from "./edm/edm";
import EDMENPage from "./EN/edm/edmen";
// import Test from "./pages/Test";

const SCGSRouter = createBrowserRouter([
  { path: `/`, errorElement: <NotFound />, element: <Main /> },
  ...[
    "introduce",
    "partner",
    "business",
    "news",
    "recruit",
    "cs",
    "sitemap",
    "post",
    "policy",
  ].map((path) => ({
    path: `/${path}`,
    errorElement: <NotFound />,
    element: <Main />,
  })),
  {
    path: "/recruit/:id",
    errorElement: <NotFound />,
    element: <Main />,
  },
  { path: "/news/news/:id?", element: <Main />, errorElement: <NotFound /> },
  {
    path: "/edm/:type/:id?",
    element: <EDMPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/EN/edm/:type/:id?",
    element: <EDMENPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/news/disclose/:id?",
    element: <Main />,
    errorElement: <NotFound />,
  },
  ...["it", "ai", "solar"].map((path) => ({
    path: `/business/${path}`,
    element: <Main />,
    errorElement: <NotFound />,
  })),
  ...["dell", "seagate", "qnap", "arista"].map((path) => ({
    path: `/business/it/${path}`,
    element: <Main />,
    errorElement: <NotFound />,
  })),
]);
const Router = () => {
  return <RouterProvider router={SCGSRouter} />;
};

export default Router;
