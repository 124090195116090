import { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { Link } from "react-router-dom";

const TextList = [
  {
    class: "business-it",
    translation: "IT Solution",
    title: "IT Solution",
    contents: ["dell", "seagate", "qnap", "arista"], // "cloud", "ito"
    link: ["/business/it/dell"],
    icons: <Icons.IconNetwork />,
  },
  {
    class: "business-ai",
    translation: "AI Solution",
    title: "AI Solution",
    contents: ["solar"],
    link: ["/business/ai"],
    icons: <Icons.IconSolar />,
  },
  {
    class: "business-solar",
    translation: "mainSolarBusiness",
    title: "신재생에너지사업",
    contents: ["solar"],
    link: ["/business/solar"],
    icons: <Icons.IconSolar />,
  },
];

const BusinessStyle = styled.ul`
  .scroll {
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    overflow: hidden;
    position: relative;
  }
`;
const BusinessMainStyle = styled.div`
  .scroll {
    min-height: 600px;
  }
  .banner-image {
    background: url("/background/intro-9.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    .banner-text {
      margin: auto;
      background: #ffffff99;
      color: #000;
      width: 100%;
      padding: 50px 0;
      text-align: center;
      h1 {
        font-size: 50px;
        line-height: 1em;
        margin: auto;
      }
    }
  }
  @media (max-width: 768px) {
    .scroll {
      min-height: 400px;
    }
    .banner-image {
      .banner-text {
        h1 {
          font-size: 30px;
        }
      }
    }
  }
`;
const BusinessMain = ({ t }: any) => {
  return (
    <BusinessMainStyle>
      <div className="banner-image scroll">
        <motion.div
          initial={{ transform: "scaleX(0)", opacity: 0 }}
          animate={{ transform: "scaleX(1)", opacity: 1 }}
          transition={{ ease: "easeOut", duration: 0.7 }}
          className="banner-text"
        >
          <motion.h1
            initial={{ translateY: "-50px", opacity: 0 }}
            animate={{ translateY: "0", opacity: 1 }}
            transition={{ delay: 0.3, ease: "easeInOut", duration: 1 }}
          >
            {t("business")}
          </motion.h1>
        </motion.div>
      </div>
    </BusinessMainStyle>
  );
};

const MainBusinessStyle2 = styled.div`
  position: relative;
  color: #fff;
  text-align: center;
  .scroll {
    max-width: 1200px;
    width: 95%;
    min-height: 600px;
    height: 600px;
    margin: 30px auto;
  }
  h1 {
    font-size: 30px;
    background-color: #061321;
    z-index: 1;
    word-break: keep-all;
  }
  ul {
    display: flex;
    height: calc(100%);
    &:hover {
      article {
        filter: grayscale();
        opacity: 0.5;
      }
      article:hover {
        filter: unset;
        opacity: 1;
        width: 100%;
        header p {
          width: 70px;
        }
      }
    }
    article {
      height: calc(100%);
      width: calc(100% / 3);
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100vw auto;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      position: relative;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      header {
        margin-top: 70px;
        p {
          transition: 0.3s;
          font-size: 0;
          width: 50px;
          margin: 0 auto;
        }
        h2 {
          transition: 0.3s;
          font-size: 30px;
          max-width: 200px;
          margin: 20px auto;
          word-break: keep-all;
        }
      }
      section {
        p {
          transition: 0.3s;
          span + span {
            &::before {
              content: "ㆍ";
            }
          }
        }
      }
    }
    .business-it {
      background-color: #353846;
      background-image: linear-gradient(
          to top,
          rgba(53, 56, 70, 0) 0%,
          #353846 70%
        ),
        url("/background/network.jpg");
    }
    .business-ai {
      background-color: #920eb3;
      background-image: linear-gradient(to top, #2cd3fd00 0%, #440c53 80%),
        url("/background/business-ai-1.jpg");
    }
    .business-solar {
      background-color: #025486;
      background-image: linear-gradient(
          to top,
          rgba(2, 84, 134, 0) 0%,
          #025486 70%
        ),
        url("/background/solar2.jpg");
    }
  }
  @media (max-width: 768px) {
    .scroll {
      height: unset;
    }
    h1 {
      padding: 20px 0;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      z-index: unset;
      color: #000;
    }
    ul {
      flex-direction: column;
      &:hover {
        article {
          filter: unset;
          opacity: unset;
        }
        article:hover {
          width: unset;
        }
      }
      article {
        width: 100%;
        margin-bottom: 2.5%;
        header {
          margin-top: unset;
          min-height: 150px;
          height: calc((100vh - 70px) / 4);
          display: flex;
          h2 {
            font-size: 25px;
            margin: auto;
          }
        }
      }
    }
  }
`;
const MainBusiness2 = ({ t }: any) => {
  return (
    <MainBusinessStyle2>
      <div className="scroll">
        <ul>
          {TextList.map((res, idx) => {
            return (
              <article className={`${res.class}`} key={idx}>
                <header>
                  <h2>{t(res.translation)}</h2>
                </header>
                <Link to={res.link[0]}></Link>
              </article>
            );
          })}
        </ul>
      </div>
    </MainBusinessStyle2>
  );
};

const Business = ({ t }: any) => {
  const up1 = useRef(null);
  const up2 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    up1.current && observer.observe(up1.current);
    up2.current && observer.observe(up2.current);
  });
  return (
    <BusinessStyle className="no-select">
      <BusinessMain t={t} />
      <section className="up-soon" ref={up1}>
        <MainBusiness2 t={t} />
      </section>
    </BusinessStyle>
  );
};

export default Business;
