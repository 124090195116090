import { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import RatioCheck from "../utils/RatioResize";
import SlideComponent from "./SlideComponent";
import API from "../api/api";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Icons from "../assets/icons";
import { debounce } from "lodash";
import Popup from "./Popup";

const TextList = [
  {
    class: "business-it",
    translation: "IT Solution",
    title: "IT Solution",
    contents: ["solution", "cloud", "ito"],
    link: ["/business/it"],
    icons: <Icons.IconNetwork />,
  },
  {
    class: "business-ai",
    translation: "AI Solution",
    title: "AI Solution",
    contents: ["solar"],
    link: ["/business/ai"],
    icons: <Icons.IconSolar />,
  },
  {
    class: "business-solar",
    translation: "mainSolarBusiness",
    title: "신재생에너지사업",
    contents: ["solar"],
    link: ["/business/solar"],
    icons: <Icons.IconSolar />,
  },
];

const MainComponentStyle = styled.ul`
  .scroll {
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100 - 50px);
    margin: auto;
    overflow: hidden;
    position: relative;
  }
`;

const MainVideoStyle = styled.div`
  .main-video {
    background: #12363b;
    position: relative;
    .videoSize {
      width: 100%;
      height: 100%;
      video {
        width: 100vw;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
      }
    }
    .videoText {
      position: absolute;
      top: 0;
      left: 0;
      background: #00000066;
      color: #fff;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      text-align: center;
      .slide-style {
        word-break: keep-all;
        max-width: 800px;
        margin: auto;
        h1 {
          line-height: 1.3em;
          font-size: 60px;
        }
        p {
          font-size: 40px;
          margin-top: 20px;
        }
      }
    }
    .animate-scroll {
      position: absolute;
      z-index: 4;
      width: 50px;
      height: 50px;
      bottom: 20px;
      left: 50%;
      transform: translate(-50%, 0%);
      display: flex;
      transition: 0.3s;
      .scroll-area {
        margin: auto;
        width: 40px;
        height: 40px;
        transition: 0.3s;
        font-size: 0;
        text-align: center;
        .scroll-img {
          position: relative;
          width: 20px;
          height: 35px;
          margin: auto;
          border: 2px solid #fff;
          border-radius: 50px;
          transition: 0.3s;
          ::before {
            content: "";
            width: 1px;
            height: 10px;
            background-color: #fff;
            display: flex;
            position: absolute;
            right: 7.5px;
          }
          .scroll-img-dot {
            height: 5px;
            width: 5px;
            margin: 5px auto;
            border-radius: 50px;
            background-color: #fff;
          }
        }
        .scroll-text {
          font-size: 12px;
          color: #fff;
        }
        .scroll-aside {
          position: relative;
          min-width: 12px;
          min-height: 12px;
          height: 12px;
          margin: 0 auto;
          transition: 0.3s;
          ::before,
          ::after {
            position: absolute;
            content: "";
            width: 8px;
            height: 2px;
            background-color: #fff;
            bottom: 5px;
          }
          ::before {
            transform: rotate(45deg);
            left: 0px;
          }
          ::after {
            transform: rotate(-45deg);
            right: 0px;
          }
        }
        &.scroll1 {
          .scroll-img {
            .scroll-img-dot {
              animation: animateScroll1 3s ease-in-out 0s infinite forwards;
            }
          }
          .scroll-text {
            left: 50%;
          }
          .scroll-bounce {
            animation: bounce 3s ease-in-out 0s infinite forwards;
            position: absolute;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    @keyframes animateScroll1 {
      0% {
        height: 5px;
        margin: 3px auto;
      }
      2% {
        height: 10px;
      }
      15% {
        height: 5px;
        margin: 10px auto;
      }
      30% {
        height: 5px;
        margin: 10px auto;
      }
      50% {
        height: 10px;
        margin: 3px auto;
      }
      57% {
        height: 5px;
        margin: 3px auto;
      }
      100% {
        height: 5px;
        margin: 3px auto;
      }
    }
    @keyframes bounce {
      0% {
        top: 110%;
      }
      10% {
        top: 100%;
      }
      20% {
        top: 110%;
      }
      30% {
        top: 105%;
      }
      40% {
        top: 110%;
      }
      100% {
        top: 110%;
      }
    }
  }
  @media (max-width: 768px) {
    .main-video {
      .videoText {
        .slide-style {
          h1 {
            font-size: 30px;
          }
          p {
            font-size: 20px;
            margin-top: 10px;
          }
          .slick-dots {
            background-color: #fff;
          }
        }
      }
      .animate-scroll {
        display: none;
      }
    }
  }
`;
const MainVideo = ({ t }: any) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    videoRef.current &&
      videoRef.current.addEventListener("resize", () => {
        RatioCheck();
      });
  }, [videoRef]);

  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    pauseOnHover: true,
  };

  return (
    <MainVideoStyle>
      <div
        ref={videoBoxRef}
        className="scroll banner-image main-video check-ratio"
      >
        <div className="videoSize">
          <video
            ref={videoRef}
            loop
            autoPlay
            muted
            playsInline
            src="/video/main.mp4"
          />
        </div>
        <div className="videoText">
          <SlideComponent settings={settings}>
            <div className="slide-style">
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                {t("main01")}
              </motion.h1>
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                {t("main01_1")}
              </motion.h1>
              <motion.p
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1, delay: 0.3 }}
              >
                {t("main02")}
              </motion.p>
            </div>
            <div className="slide-style">
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                {t("main03")}
              </motion.h1>
              <motion.h1
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1 }}
              >
                {t("main03_1")}
              </motion.h1>
              <motion.p
                initial={{ translateY: "-50px", opacity: 0 }}
                animate={{ translateY: "0", opacity: 1 }}
                transition={{ ease: "easeInOut", duration: 1, delay: 0.3 }}
              >
                {t("main04")}
              </motion.p>
            </div>
          </SlideComponent>
        </div>
        <div className="animate-scroll">
          <div className="scroll-area scroll1">
            <div className="scroll-img">
              <div className="scroll-img-dot"></div>
            </div>
            <span className="scroll-text scroll-bounce">Scroll</span>
          </div>
        </div>
      </div>
    </MainVideoStyle>
  );
};

const MainBusinessStyle2 = styled.div`
  position: relative;
  color: #fff;
  text-align: center;
  .scroll {
    max-width: 1200px;
    width: 95%;
    display: flex;
    max-height: calc(var(--vh, 1vh) * 100 - 50px);
  }
  h1 {
    font-size: 30px;
    background-color: #061321;
    z-index: 1;
    word-break: keep-all;
  }
  ul {
    width: 100%;
    margin: auto;
    display: flex;
    height: calc(100% - 100px);
    max-height: 700px;
    &:hover {
      article {
        filter: grayscale();
        opacity: 0.5;
      }
      article:hover {
        filter: unset;
        opacity: 1;
        width: 100%;
        header p {
          width: 70px;
        }
      }
    }
    article {
      height: calc(100%);
      width: calc(100% / 3);
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 100vw auto;
      transition: 0.3s;
      display: flex;
      flex-direction: column;
      position: relative;
      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
      }
      header {
        margin-top: 70px;
        p {
          transition: 0.3s;
          font-size: 0;
          width: 50px;
          margin: 0 auto;
        }
        h2 {
          transition: 0.3s;
          font-size: 33px;
          max-width: 200px;
          margin: 20px auto;
          word-break: keep-all;
        }
      }
      section {
        p {
          transition: 0.3s;
          span + span {
            &::before {
              content: "ㆍ";
            }
          }
        }
      }
    }
    .business-it {
      background-color: rgb(53, 56, 70);
      background-image: linear-gradient(
          to top,
          rgba(53, 56, 70, 0) 0%,
          rgba(53, 56, 70, 1) 70%
        ),
        url("/background/network.jpg");
    }
    .business-ai {
      background-color: #920eb3;
      background-image: linear-gradient(to top, #2cd3fd00 0%, #440c53 80%),
        url("/background/business-ai-1.jpg");
    }
    .business-solar {
      background-color: rgb(2, 84, 134);
      background-image: linear-gradient(
          to top,
          rgba(2, 84, 134, 0) 0%,
          rgba(2, 84, 134, 1) 70%
        ),
        url("/background/solar2.jpg");
    }
    .net-1,
    .solar-1,
    .solar-2,
    .manage-1 {
      stroke: #fff;
    }
    .cs-1,
    .cs-2 {
      stroke: #000;
    }
  }
  button {
    display: flex;
    margin: 20px auto 0;
    border: 1px #ffffff solid;
    background: #ffffff66;
    border-radius: 2px;
    color: #fff;
    transition: 0.3s;
    a {
      padding: 2px 20px;
    }
    &:hover {
      background: #ffffff;
      color: #000;
    }
  }
  @media (max-width: 768px) {
    .scroll {
      margin: 30px auto;
      height: unset;
      max-height: unset;
    }
    h1 {
      padding: 20px 0;
      position: relative;
      top: unset;
      left: unset;
      transform: unset;
      z-index: unset;
      color: #000;
    }
    ul {
      flex-direction: column;
      max-height: unset;
      &:hover {
        article {
          filter: unset;
          opacity: unset;
        }
        article:hover {
          width: unset;
        }
      }
      article {
        width: 100%;
        margin-bottom: 2.5%;
        background-position: center;
        background-size: cover;
        header {
          margin-top: unset;
          min-height: 150px;
          height: calc((100vh - 70px) / 4);
          display: flex;
          h2 {
            font-size: 25px;
            margin: auto;
            max-width: unset;
          }
        }
      }
      .business-it {
        background-color: rgb(53, 56, 70);
        background-image: linear-gradient(
            to top,
            rgba(53, 56, 70, 0) 0%,
            rgba(53, 56, 70, 1) 70%
          ),
          url("/background/network.jpg");
      }
      .business-solar {
        background-color: rgb(2, 84, 134);
        background-image: linear-gradient(
            to top,
            rgba(2, 84, 134, 0) 0%,
            rgba(2, 84, 134, 1) 70%
          ),
          url("/background/solar2.jpg");
      }
      .business-manage {
        background-color: rgb(106, 159, 191);
        background-image: linear-gradient(
            to top,
            rgba(106, 159, 191, 0) 0%,
            rgba(106, 159, 191, 1) 70%
          ),
          url("/background/manage.jpg");
      }
      .business-cs {
        background-color: rgb(213, 213, 215);
        background-image: linear-gradient(
            to top,
            rgba(213, 213, 215, 0) 0%,
            #8c8c8c 70%
          ),
          url("/background/cs3.jpg");
        h2 {
          line-height: unset;
        }
        button {
          border: 1px #000000 solid;
          background: #ffffff66;
          color: #000000;
          &:hover {
            background: #ffffff;
            color: #000;
          }
        }
      }
    }
  }
`;
const MainBusiness2 = ({ t }: any) => {
  return (
    <MainBusinessStyle2>
      <div className="scroll">
        <ul>
          {TextList.map((res, idx) => {
            return (
              <article className={`${res.class}`} key={idx}>
                <header>
                  <h2>{t(res.translation)}</h2>
                </header>
                <Link to={res.link[0]}></Link>
              </article>
            );
          })}
        </ul>
      </div>
    </MainBusinessStyle2>
  );
};

const MainNewsStyle = styled.section`
  background: url("/intro_line_extend.svg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  .scroll {
    max-height: calc(var(--vh, 1vh) * 100 - 50px);
    padding: 0px 0 100px;
    max-width: 1200px;
    width: 95%;
  }
  .main-news {
    display: flex;
    position: relative;
    .news-frame {
      max-width: 1000px;
      width: 95%;
      margin: auto;
      .news-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        .news-contents {
          aspect-ratio: 4/3;
          background: #ffffffcc;
          &.no-image {
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
          }
          background-position: center;
          /* news - contain */
          background-size: cover;
          background-repeat: no-repeat;
          &.left-area {
            background-color: #eee;
          }
          &.right-area {
            padding: 20px 10px;
            transition: 0.3s;
            h1 {
              line-height: 1.2em;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              color: #026;
              font-size: 20px;
              span {
                font-size: 1rem;
                font-weight: normal;
                margin-left: 5px;
              }
            }
            .news-text {
              margin: 20px 0;
              text-overflow: ellipsis;
              display: -webkit-box;
              word-break: break-word;
              overflow: hidden;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              font-size: 16px;
            }
          }
          button {
            font-size: 14px;
            border: 1px #05f solid;
            padding: 5px 20px;
            margin-left: auto;
            border-radius: 3px;
            transition: 0.3s;
            background: none;
            font-weight: bold;
            color: #05f;
            position: relative;
            a {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
            &:hover {
              background: #05f;
              color: #fff;
            }
            &:active {
              background: #04c;
              transform: translate(1px, 1px);
              transition: 0.2s;
            }
          }
        }
      }
    }
    .slick-slider {
      .slick-list {
        aspect-ratio: 2/1;
        .slick-track {
          height: 100%;
          .slick-slide {
            padding: 10px;
            transition: 0.3s;
            &.slick-center {
              padding: 10px;
              h1 {
                color: white;
              }
              background: #0088ffdf;
              .news-text {
                color: white;
              }
              .right-area {
                background: none;
              }
              button {
                color: #fff;
                border-color: #fff;
                &:hover {
                  background-color: #fff;
                  color: #37f;
                }
              }
            }
            & > div {
              height: 100%;
              & > div {
                height: 100%;
              }
            }
          }
        }
      }
      .slick-dots {
        bottom: -40px;
        li {
          background: #00000044;
          &.slick-active {
            background: #000000ee;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .main-news {
      .news-frame {
        width: 90%;
        .slick-slider {
          .slick-list {
            aspect-ratio: unset;
          }
        }
        .news-box {
          .news-contents {
            min-height: 200px;
            aspect-ratio: unset;
            &.no-image {
              background-size: 80%;
            }
            width: 100%;
          }
        }
      }
    }
  }
`;
const MainNews = ({ t }: any) => {
  const [snsList, setSnsList] = useState<any[]>([]);
  // news일 경우
  // const [mainData, setMainData] = useState<any[]>([]);
  // const parser = new DOMParser();
  const up1 = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };
  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });
  // news일 경우
  // useEffect(() => {
  //   API.MAIN_POST(setMainData);
  // }, [setMainData]);
  useEffect(() => {
    API.INSTAGRAM_GET_POSTS({ setDataList: setSnsList });
  }, [setSnsList]);
  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    centerMode: true,
    centerPadding: "0",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <MainNewsStyle>
      <div className="scroll main-news">
        <div className="news-frame up-soon" ref={up1}>
          <SlideComponent settings={settings}>
            {/*
            main - news일 경우
             {mainData &&
              mainData.map((res, idx) => {
                return (
                  <div className="news-box" key={idx}>
                    {res.imageList && res.imageList.length ? (
                      <div
                        className="news-contents left-area"
                        style={{
                          backgroundImage: `url(${res.imageList[0]})`,
                        }}
                      ></div>
                    ) : (
                      <div
                        className="news-contents no-image left-area"
                        style={{
                          backgroundColor: "#eee",
                          backgroundImage: `url(logo_sm_color.svg)`,
                        }}
                      ></div>
                    )}
                    <div className="news-contents right-area">
                      <h1>{res.title}</h1>
                      <div className="news-text">
                        {
                          parser.parseFromString(res.content, "text/html")
                            .documentElement.textContent
                        }
                      </div>
                      <button className="pointer">
                        {t("more")}
                        <Link to={`/news/news/${res.id}`} />
                      </button>
                    </div>
                  </div>
                );
              })} */}

            {/* sns일 경우 */}
            {snsList &&
              snsList.map(
                (
                  res: {
                    caption: any;
                    id: any;
                    media_url: any;
                    permalink: any;
                    timestamp: string;
                  },
                  idx
                ) => {
                  const date = new Date(res.timestamp);
                  return (
                    idx < 5 && (
                      <div className="news-box" key={idx}>
                        {res.media_url && res.media_url ? (
                          <div
                            className="news-contents left-area"
                            style={{
                              backgroundImage: `url(${res.media_url})`,
                            }}
                          ></div>
                        ) : (
                          <div
                            className="news-contents no-image left-area"
                            style={{
                              backgroundColor: "#eee",
                              backgroundImage: `url(logo_sm_color.svg)`,
                            }}
                          ></div>
                        )}
                        <div className="news-contents right-area">
                          <h1>
                            {date.getFullYear().toString()}.
                            {date.getMonth() < 10
                              ? "0" + date.getMonth().toString()
                              : date.getMonth().toString()}
                            .
                            {date.getDay() < 10
                              ? "0" + date.getDay().toString()
                              : date.getDay().toString()}
                            <span>
                              {date.getHours() < 10
                                ? "0" + date.getHours().toString()
                                : date.getHours().toString()}
                              :
                              {date.getMinutes() < 10
                                ? "0" + date.getMinutes().toString()
                                : date.getMinutes().toString()}
                            </span>
                          </h1>
                          <div className="news-text">{res.caption}</div>
                          <button className="pointer">
                            {t("more")}
                            <Link to={`${res.permalink}`} target="_blank" />
                          </button>
                        </div>
                      </div>
                    )
                  );
                }
              )}
          </SlideComponent>
        </div>
      </div>
    </MainNewsStyle>
  );
};

const MainComponent = ({ t, i18n }: any) => {
  const main = useRef(null);
  const up1 = useRef(null);
  const target = useRef(null);
  const options = {
    root: null,
    rootMargin: "-10%",
    threshold: 0,
  };

  let idx = 0;
  const WheelEventHandler = useCallback((e: any) => {
    if (e.deltaY > 0) {
      if (idx < 3) {
        idx++;
      }
      window.document.querySelector(".body")?.scrollTo({
        top: Number(window.document.querySelector(".body")?.clientHeight) * idx,
        behavior: "smooth",
      });
    } else {
      if (idx > 0) {
        idx--;
      }
      window.document.querySelector(".body")?.scrollTo({
        top: Number(window.document.querySelector(".body")?.clientHeight) * idx,
        behavior: "smooth",
      });
    }
  }, []);

  const throttledWheel = debounce((e) => {
    WheelEventHandler(e);
  }, 200);

  useEffect(() => {
    const observer = new IntersectionObserver((e) => {
      e.map((f) => {
        return f.isIntersecting && f.target.classList.add("up");
      });
    }, options);
    [up1].forEach((ref) => ref.current && observer.observe(ref.current));
  });

  return (
    <MainComponentStyle
      onWheel={(e) =>
        Number(window.document.querySelector(".body")?.clientWidth) > 768 &&
        throttledWheel(e)
      }
      className="no-select"
      ref={main}
    >
      <section ref={target}>
        <MainVideo t={t} />
      </section>
      <section className="up-soon" ref={up1}>
        <MainBusiness2 t={t} />
      </section>
      <MainNews t={t} />
      {i18n.language === "ko" ? <Popup /> : <></>}
    </MainComponentStyle>
  );
};

export default MainComponent;
