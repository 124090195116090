import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import HeadNav from "../components/Header";
import MainComponent from "../components/MainComponent";
import CustomerService from "../components/CustomerService";
import Footer from "../components/Footer";
import Intro from "../components/Intro";
// import Intro2 from "../components/fe_ko-Intro_movieComponent_backup";
import Partners from "../components/Partners";
import Recruit from "../components/Recruit";
import BoardDetail from "../components/BoardDetail";
import News from "../components/News";
import NewsBoardDetail from "../components/NewsBoardDetail";
import Business from "../components/Business";
import BusinessSolar from "../components/BusinessSolar";
import BusinessItSolution from "../components/BusinessItSolution";
import BusinessItAi from "../components/BusinessItAi";
import { useTranslation } from "react-i18next";
import Icons from "../assets/icons";
import SiteMap from "../components/Sitemap";
import MemberPost from "../components/MemberPost";
import Policy from "../components/Policy";
const AdminMainStyle = styled.div`
  position: relative;
  overflow-x: hidden;
  .body {
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh, 1vh) * 100 - 50px);
    height: calc(var(--vh, 1vh) * 100 - 50px);
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  > aside {
    width: 50px;
    aspect-ratio: 1;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    background: #ffffffaa;
    transition: 0.3s;
    opacity: 0;
    :hover {
      background: #eeeeee99;
    }
    :active {
      background: #eeeeee;
    }
    &.active {
      opacity: 1;
    }
    svg {
      width: 25px;
      height: 25px;
      margin: auto;
      rotate: -90deg;
      path {
        stroke: #026;
      }
    }
  }
  @media (max-width: 768px) {
    .body {
      overflow-y: auto !important;
      margin-top: 50px;
      height: calc(var(--vh, 1vh) * 100 - 50px);
    }
  }
`;
const AdminMain = () => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname.split("/")[2];
  const path3 = location.pathname.split("/")[3];
  const bodyRef = useRef<any>(null);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1,
    };
    let observer: any;
    setTimeout(
      () => (
        (observer = new IntersectionObserver((e) => {
          if (
            e[0].intersectionRatio === 1 &&
            window.document.querySelector(".scroll")
          ) {
            window.document.querySelector("#aside")?.classList.remove("active");
          } else {
            window.document.querySelector("#aside")?.classList.add("active");
          }
        }, options)),
        [window.document.querySelector(".scroll")].forEach(
          (ref) => ref && observer.observe(ref)
        )
      ),
      50
    );
  }, [location]);
  useEffect(() => {
    path === ""
      ? (bodyRef.current.style.overflowY = "hidden")
      : (bodyRef.current.style.overflowY = "auto");
  }, [path]);

  return (
    <AdminMainStyle>
      <HeadNav t={t} i18n={i18n} />
      <div ref={bodyRef} className="body">
        {!path2 && (
          <>
            {path === "" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <MainComponent t={t} i18n={i18n} />
              </motion.div>
            )}
            {path === "introduce" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Intro t={t} i18n={i18n} />
              </motion.div>
            )}
            {/* 
            {path === "introduce" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Intro2 t={t} i18n={i18n} />
              </motion.div>
            )} */}
            {path === "partner" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Partners t={t} />
              </motion.div>
            )}
            {path === "recruit" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Recruit t={t} i18n={i18n} />
              </motion.div>
            )}
            {path === "cs" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <CustomerService t={t} />
              </motion.div>
            )}
            {path === "news" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <News t={t} />
              </motion.div>
            )}
            {path === "business" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Business t={t} />
              </motion.div>
            )}
            {path === "sitemap" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <SiteMap t={t} />
              </motion.div>
            )}
            {path === "post" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <MemberPost />
              </motion.div>
            )}
            {path === "policy" && (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeInOut", duration: 0.5 }}
              >
                <Policy t={t} />
              </motion.div>
            )}
          </>
        )}
        {path2 !== undefined && path === "recruit" && <BoardDetail t={t} />}
        {path === "news" && path3 && <NewsBoardDetail t={t} />}
        {path === "business" &&
          ((path2 === "it" && (
            <BusinessItSolution t={t} i18n={i18n} state={path3} />
          )) ||
            (path2 === "ai" && <BusinessItAi i18n={i18n} t={t} />) ||
            (path2 === "solar" && <BusinessSolar i18n={i18n} t={t} />))}
        <Footer t={t} i18n={i18n} />
      </div>
      <aside
        id="aside"
        onClick={() => {
          window.document
            .querySelector(".body")
            ?.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        {<Icons.IconThinArrow />}
      </aside>
    </AdminMainStyle>
  );
};

export default AdminMain;
