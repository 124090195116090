import { ReactComponent as LogoBlackS } from "./icons/logo_sm_black.svg";
import { ReactComponent as LogoBlackW } from "./icons/logo_sm_white.svg";
import { ReactComponent as LogoBlackWEn } from "./icons/logo_sm_white_en.svg";
import { ReactComponent as LogoBlackC } from "./icons/logo_sm_color.svg";
import { ReactComponent as LogoBlackCEn } from "./icons/logo_sm_color_en.svg";
import { ReactComponent as LogoFaviB } from "./icons/favi_black.svg";
import { ReactComponent as LogoFaviC } from "./icons/favi_color.svg";
import { ReactComponent as LogoFaviW } from "./icons/favi_white.svg";
import { ReactComponent as LogoVertical } from "./icons/logo_sm_color_vertical.svg";
import { ReactComponent as LogoVerticalEn } from "./icons/logo_sm_color_vertical_en.svg";
import { ReactComponent as LogoDalian } from "./icons/logo_dalian.svg";
import { ReactComponent as LogoThailand } from "./icons/logo_thailand.svg";
import { ReactComponent as KakaoTalk } from "./icons/sns-kakaotalk.svg";
import { ReactComponent as FaceBook } from "./icons/sns-facebook.svg";
import { ReactComponent as Instargram } from "./icons/sns-insta.svg";
import { ReactComponent as Blog } from "./icons/sns-blog.svg";
import { ReactComponent as CountryCn } from "./icons/country-cn.svg";
import { ReactComponent as CountryTh } from "./icons/country-th.svg";
import { ReactComponent as CountryUs } from "./icons/country-us.svg";
import { ReactComponent as ArrowRightW } from "./icons/arrow-right-w.svg";
import { ReactComponent as BrandAristaW } from "./icons/brand-arista-w.svg";
import { ReactComponent as BrandBenqW } from "./icons/brand-benq-w.svg";
import { ReactComponent as BrandSeagateW } from "./icons/brand-seagate-w.svg";
import { ReactComponent as BrandDellTechnologiesW } from "./icons/brand-delltechnologies-w.svg";
import { ReactComponent as BrandArista } from "./icons/brand-arista.svg";
import { ReactComponent as BrandArista2 } from "./icons/brand-arista-2.svg";
import { ReactComponent as BrandBenq } from "./icons/brand-benq.svg";
import { ReactComponent as BrandQnap } from "./icons/brand-qnap.svg";
import { ReactComponent as BrandQnapGray } from "./icons/brand-qnap-gray.svg";
import { ReactComponent as BrandSeagate } from "./icons/brand-seagate.svg";
import { ReactComponent as BrandTpLink } from "./icons/brand-tplink.svg";
import { ReactComponent as BrandTpLinkNotext } from "./icons/brand-tplink-notext.svg";
import { ReactComponent as BrandTpLinkNotextW } from "./icons/brand-tplink-notext-w.svg";
import { ReactComponent as BrandDell } from "./icons/brand-dell.svg";
import { ReactComponent as BrandDellW } from "./icons/brand-dell-w.svg";
import { ReactComponent as BrandDellEmc } from "./icons/brand-dellemc.svg";
import { ReactComponent as BrandDellTechnologies } from "./icons/brand-delltechnologies.svg";
import { ReactComponent as BrandDellTechnologies2 } from "./icons/brand-delltechnologies-2.svg";
import { ReactComponent as BrandIto } from "./icons/brand-ito-c.svg";
import { ReactComponent as BrandItoW } from "./icons/brand-ito-w.svg";
import { ReactComponent as BrandManaging } from "./icons/brand-managing-c.svg";
import { ReactComponent as BrandManagingW } from "./icons/brand-managing-w.svg";
import { ReactComponent as BrandService } from "./icons/brand-service-c.svg";
import { ReactComponent as BrandServiceW } from "./icons/brand-service-w.svg";
import { ReactComponent as BrandJaSolar } from "./icons/brand-jasolar.svg";
import { ReactComponent as BrandJaSolar2 } from "./icons/brand-jasolar2.svg";
import { ReactComponent as BrandJaSolar3 } from "./icons/brand-jasolar3.svg";
import { ReactComponent as BrandJinkoSolar } from "./icons/brand-jinkosolar.svg";
import { ReactComponent as BrandJinkoSolar2 } from "./icons/brand-jinkosolar2.svg";
import { ReactComponent as BrandJinkoSolar3 } from "./icons/brand-jinkosolar3.svg";
import { ReactComponent as BrandNaff } from "./icons/brand-naff.svg";
import { ReactComponent as BrandNaff2 } from "./icons/brand-naff2.svg";
import { ReactComponent as BrandNaffW } from "./icons/brand-naff-w.svg";
import { ReactComponent as BrandSalesforce } from "./icons/brand-salesforce.svg";
import { ReactComponent as BrandSalesforce2 } from "./icons/brand-salesforce2.svg";
import { ReactComponent as BrandSalesforceW } from "./icons/brand-salesforce-w.svg";
import { ReactComponent as BrandRisenEnergy } from "./icons/brand-risenenergy.svg";
import { ReactComponent as BrandRisenEnergy2 } from "./icons/brand-risenenergy2.svg";
import { ReactComponent as BrandRisenEnergy3 } from "./icons/brand-risenenergy3.svg";
import { ReactComponent as BrandTrinaSolar } from "./icons/brand-trinasolar.svg";
import { ReactComponent as BrandTrinaSolar2 } from "./icons/brand-trinasolar2.svg";
import { ReactComponent as BrandTrinaSolar3 } from "./icons/brand-trinasolar3.svg";
import { ReactComponent as BrandHyosung } from "./icons/brand-hyosung.svg";
import { ReactComponent as BrandSolis } from "./icons/brand-solis.svg";
import { ReactComponent as BrandGrowatt } from "./icons/brand-growatt.svg";
import { ReactComponent as BrandSungrow } from "./icons/brand-sungrow.svg";
import { ReactComponent as BrandJbcorp } from "./icons/brand-jbcorp.svg";
import { ReactComponent as BrandJbcorp2 } from "./icons/brand-jbcorp2.svg";
import { ReactComponent as BrandHomeTelier } from "./icons/brand-hometelier.svg";
import { ReactComponent as BrandScgGrid } from "./icons/brand-scggrid.svg";
import { ReactComponent as BrandScgLab } from "./icons/brand-scglab.svg";
import { ReactComponent as BrandScgLab2 } from "./icons/brand-scglab2.svg";
import { ReactComponent as BrandSeoulCng } from "./icons/brand-seoulcng.svg";
import { ReactComponent as BrandSeoulCng2 } from "./icons/brand-seoulcng2.svg";
import { ReactComponent as BrandSeoulgas } from "./icons/brand-seoulgas.svg";
import { ReactComponent as BrandSeoulgas2 } from "./icons/brand-seoulgas2.svg";
import { ReactComponent as BrandSeoulgas3 } from "./icons/brand-seoulgas3.svg";
import { ReactComponent as BrandIncheongas } from "./icons/brand-incheongas.svg";
import { ReactComponent as BrandPython } from "./icons/brand-python.svg";
import { ReactComponent as BrandPytorch } from "./icons/brand-pytorch.svg";
import { ReactComponent as BrandRedHat } from "./icons/brand-red-hat.svg";
import { ReactComponent as BrandRockyLinux } from "./icons/brand-rocky-linux.svg";
import { ReactComponent as BrandTensorflow } from "./icons/brand-tensorflow.svg";
import { ReactComponent as BrandUbuntu } from "./icons/brand-ubuntu.svg";
import { ReactComponent as BrandCuda } from "./icons/brand-cuda.svg";
import { ReactComponent as BrandKeras } from "./icons/brand-keras.svg";
import { ReactComponent as BrandCaffe2 } from "./icons/brand-caffe2.svg";
import { ReactComponent as BrandMemcheck } from "./icons/brand-memcheck.svg";
import { ReactComponent as BrandSuse } from "./icons/brand-suse.svg";
import { ReactComponent as BusinessSolar } from "./icons/business-solar.svg";
import { ReactComponent as IconDownload } from "./icons/icon-download.svg";
import { ReactComponent as IconDoubleArrowRight } from "./icons/icon-double-right-arrow.svg";
import { ReactComponent as IconArrowLeft } from "./icons/icon-arrow-left.svg";
import { ReactComponent as IconArrowRight } from "./icons/icon-arrow-right.svg";
import { ReactComponent as IconThinArrow } from "./icons/icon-thin-arrow.svg";
import { ReactComponent as IconLocation } from "./icons/icon-location.svg";
import { ReactComponent as IconSi } from "./icons/icon-si.svg";
import { ReactComponent as IconSm } from "./icons/icon-sm.svg";
import { ReactComponent as IconInfra } from "./icons/icon-infra.svg";
import { ReactComponent as IconSiIto } from "./icons/icon-si-ito.svg";
import { ReactComponent as Icon2019 } from "./icons/icon-2019.svg";
import { ReactComponent as Icon2020 } from "./icons/icon-2020.svg";
import { ReactComponent as Icon2021 } from "./icons/icon-2021.svg";
import { ReactComponent as IconLongArrowLeft } from "./icons/icon-arrow-left-long.svg";
import { ReactComponent as IconLongArrowRight } from "./icons/icon-arrow-right-long.svg";
import { ReactComponent as IconPause } from "./icons/icon-pause.svg";
import { ReactComponent as IconPlay } from "./icons/icon-play.svg";
import { ReactComponent as IconVideo } from "./icons/icon-video.svg";
import { ReactComponent as IconClose } from "./icons/icon-close.svg";
import { ReactComponent as IconMenu } from "./icons/icon-menu.svg";
import { ReactComponent as IconCs } from "./icons/icon-cs.svg";
import { ReactComponent as IconManage } from "./icons/icon-manage.svg";
import { ReactComponent as IconNetwork } from "./icons/icon-network.svg";
import { ReactComponent as IconSolar } from "./icons/icon-solar.svg";
import { ReactComponent as IconBusiness } from "./icons/icon-business.svg";
import { ReactComponent as IconBusiness2 } from "./icons/icon-business2.svg";
import { ReactComponent as IconScale } from "./icons/icon-scale.svg";
import { ReactComponent as IconScale2 } from "./icons/icon-scale2.svg";
import { ReactComponent as IconFounding } from "./icons/icon-founding.svg";
import { ReactComponent as IconFounding2 } from "./icons/icon-founding2.svg";
import { ReactComponent as IconPartner } from "./icons/icon-partner.svg";
import { ReactComponent as IconPartner2 } from "./icons/icon-partner2.svg";
import { ReactComponent as IconPartner3 } from "./icons/icon-partner3.svg";
import { ReactComponent as IconWorker } from "./icons/icon-worker.svg";
import { ReactComponent as IconWorker2 } from "./icons/icon-worker2.svg";
import { ReactComponent as IconGrade } from "./icons/icon-grade.svg";
import { ReactComponent as IconAddress } from "./icons/icon-address.svg";
import { ReactComponent as IconCallSeagate } from "./icons/icon-call-seagate.svg";
import { ReactComponent as IconCallTplink } from "./icons/icon-call-tplink.svg";
import { ReactComponent as IconFillBag } from "./icons/icon-fill-bag.svg";
import { ReactComponent as IconFillCut } from "./icons/icon-fill-cut.svg";
import { ReactComponent as IconFillGraph } from "./icons/icon-fill-graph.svg";
import { ReactComponent as IconFillGroup } from "./icons/icon-fill-group.svg";
import { ReactComponent as IconFillHand } from "./icons/icon-fill-hand.svg";
import { ReactComponent as IconFillPeople } from "./icons/icon-fill-people.svg";
import { ReactComponent as IconTravelInsurance } from "./icons/10-travel-insurance.svg";
import { ReactComponent as IconBonus } from "./icons/01-bonus.svg";
import { ReactComponent as IconOutstanding } from "./icons/02-outstanding.svg";
import { ReactComponent as IconEducation } from "./icons/03-education.svg";
import { ReactComponent as IconCall } from "./icons/04-call.svg";
import { ReactComponent as IconCallW } from "./icons/icon-call.svg";
import { ReactComponent as IconAccident } from "./icons/05-accident.svg";
import { ReactComponent as IconHealthCheck } from "./icons/06-health-check.svg";
import { ReactComponent as IconResort } from "./icons/07-resort.svg";
import { ReactComponent as IconGift } from "./icons/08-gift.svg";
import { ReactComponent as IconCongratulatoryEvents } from "./icons/09-congratulatory-events.svg";
import { ReactComponent as IconTravelInsurance2 } from "./icons/10-travel-insurance2.svg";
import { ReactComponent as IconBonus2 } from "./icons/01-bonus2.svg";
import { ReactComponent as IconOutstanding2 } from "./icons/02-outstanding2.svg";
import { ReactComponent as IconEducation2 } from "./icons/03-education2.svg";
import { ReactComponent as IconCall2 } from "./icons/04-call2.svg";
import { ReactComponent as IconAccident2 } from "./icons/05-accident2.svg";
import { ReactComponent as IconHealthCheck2 } from "./icons/06-health-check2.svg";
import { ReactComponent as IconResort2 } from "./icons/07-resort2.svg";
import { ReactComponent as IconGift2 } from "./icons/08-gift2.svg";
import { ReactComponent as IconCongratulatoryEvents2 } from "./icons/09-congratulatory-events2.svg";
import { ReactComponent as IconIto01 } from "./icons/ito-01.svg";
import { ReactComponent as IconIto02 } from "./icons/ito-02.svg";
import { ReactComponent as IconIto03 } from "./icons/ito-03.svg";
import { ReactComponent as IconIto04 } from "./icons/ito-04.svg";
import { ReactComponent as SolarBg1 } from "./icons/solar_01.svg";
import { ReactComponent as SolarBg2 } from "./icons/solar_02.svg";
import { ReactComponent as SolarBg3 } from "./icons/solar_03.svg";
import { ReactComponent as SolarBg4 } from "./icons/solar_04.svg";
import { ReactComponent as SolarBg5 } from "./icons/solar_05.svg";
import { ReactComponent as SolarBg_en1 } from "./icons/solar_en_01.svg";
import { ReactComponent as SolarBg_en2 } from "./icons/solar_en_02.svg";
import { ReactComponent as SolarBg_en3 } from "./icons/solar_en_03.svg";
import { ReactComponent as SolarBg_en4 } from "./icons/solar_en_04.svg";
import { ReactComponent as SolarBg_en5 } from "./icons/solar_en_05.svg";
import { ReactComponent as EvBg1 } from "./icons/ev_process.svg";
import { ReactComponent as EvBg2 } from "./icons/ev_process_en.svg";
import { ReactComponent as EvMoBg1 } from "./icons/ev_process_m.svg";
import { ReactComponent as CsBg1 } from "./icons/cs_process.svg";
import { ReactComponent as CsBg2 } from "./icons/cs_process_en.svg";
import { ReactComponent as SeagateAs1 } from "./icons/seagate_as_1.svg";
import { ReactComponent as SeagateAs2 } from "./icons/seagate_as_2.svg";
import { ReactComponent as MediaStop } from "./icons/media-stop.svg";
import { ReactComponent as MediaPlay } from "./icons/media-play.svg";
import { ReactComponent as MediaPause } from "./icons/media-pause.svg";
import { ReactComponent as MediaForward } from "./icons/media-forward.svg";
import { ReactComponent as MediaBackward } from "./icons/media-backward.svg";
import { ReactComponent as MediaExpand } from "./icons/media-expand.svg";
import { ReactComponent as MediaVolumeUp } from "./icons/media-volume-high.svg";
import { ReactComponent as MediaVolumeMuted } from "./icons/media-volume-mute.svg";
import { ReactComponent as BannerRecruitImg } from "./icons/banner-recruit-img.svg";
import { ReactComponent as BannerSeagateImg } from "./icons/banner-seagate-img.svg";
import { ReactComponent as BannertplinkImg } from "./icons/banner-tplink-img.svg";
const Icons = {
  LogoBlackS,
  LogoBlackW,
  LogoBlackWEn,
  LogoBlackC,
  LogoBlackCEn,
  LogoFaviB,
  LogoFaviC,
  LogoFaviW,
  LogoVertical,
  LogoVerticalEn,
  LogoDalian,
  LogoThailand,
  KakaoTalk,
  FaceBook,
  Instargram,
  Blog,
  CountryCn,
  CountryTh,
  CountryUs,
  ArrowRightW,
  BrandAristaW,
  BrandSeagateW,
  BrandBenqW,
  BrandDellTechnologiesW,
  BrandArista,
  BrandArista2,
  BrandSeagate,
  BrandBenq,
  BrandQnap,
  BrandQnapGray,
  BrandTpLink,
  BrandTpLinkNotext,
  BrandTpLinkNotextW,
  BrandDell,
  BrandDellW,
  BrandDellEmc,
  BrandDellTechnologies,
  BrandDellTechnologies2,
  BrandIto,
  BrandItoW,
  BrandManaging,
  BrandManagingW,
  BrandService,
  BrandServiceW,
  BrandJaSolar,
  BrandJaSolar2,
  BrandJaSolar3,
  BrandJinkoSolar,
  BrandJinkoSolar2,
  BrandJinkoSolar3,
  BrandNaff,
  BrandNaff2,
  BrandNaffW,
  BrandSalesforce,
  BrandSalesforce2,
  BrandSalesforceW,
  BrandRisenEnergy,
  BrandRisenEnergy2,
  BrandRisenEnergy3,
  BrandTrinaSolar,
  BrandTrinaSolar2,
  BrandTrinaSolar3,
  BrandHyosung,
  BrandSolis,
  BrandGrowatt,
  BrandSungrow,
  BrandJbcorp,
  BrandJbcorp2,
  BrandHomeTelier,
  BrandScgGrid,
  BrandScgLab,
  BrandScgLab2,
  BrandSeoulCng,
  BrandSeoulCng2,
  BrandSeoulgas,
  BrandSeoulgas2,
  BrandSeoulgas3,
  BrandIncheongas,
  BrandPython,
  BrandPytorch,
  BrandRedHat,
  BrandRockyLinux,
  BrandTensorflow,
  BrandUbuntu,
  BrandCuda,
  BrandKeras,
  BrandCaffe2,
  BrandMemcheck,
  BrandSuse,
  BusinessSolar,
  IconDownload,
  IconDoubleArrowRight,
  IconArrowLeft,
  IconArrowRight,
  IconThinArrow,
  IconLocation,
  IconSi,
  IconSm,
  IconInfra,
  IconSiIto,
  Icon2019,
  Icon2020,
  Icon2021,
  IconLongArrowLeft,
  IconLongArrowRight,
  IconPause,
  IconPlay,
  IconVideo,
  IconClose,
  IconMenu,
  IconCs,
  IconManage,
  IconNetwork,
  IconSolar,
  IconBusiness,
  IconBusiness2,
  IconScale,
  IconScale2,
  IconFounding,
  IconFounding2,
  IconPartner,
  IconPartner2,
  IconPartner3,
  IconWorker,
  IconWorker2,
  IconGrade,
  IconAddress,
  IconCallSeagate,
  IconCallTplink,
  IconFillBag,
  IconFillCut,
  IconFillGraph,
  IconFillGroup,
  IconFillHand,
  IconFillPeople,
  IconTravelInsurance,
  IconBonus,
  IconOutstanding,
  IconEducation,
  IconCall,
  IconCallW,
  IconAccident,
  IconHealthCheck,
  IconResort,
  IconGift,
  IconCongratulatoryEvents,
  IconTravelInsurance2,
  IconBonus2,
  IconOutstanding2,
  IconEducation2,
  IconCall2,
  IconAccident2,
  IconHealthCheck2,
  IconResort2,
  IconGift2,
  IconCongratulatoryEvents2,
  IconIto01,
  IconIto02,
  IconIto03,
  IconIto04,
  SolarBg1,
  SolarBg2,
  SolarBg3,
  SolarBg4,
  SolarBg5,
  SolarBg_en1,
  SolarBg_en2,
  SolarBg_en3,
  SolarBg_en4,
  SolarBg_en5,
  EvBg1,
  EvBg2,
  EvMoBg1,
  CsBg1,
  CsBg2,
  SeagateAs1,
  SeagateAs2,
  MediaStop,
  MediaPlay,
  MediaPause,
  MediaForward,
  MediaBackward,
  MediaExpand,
  MediaVolumeUp,
  MediaVolumeMuted,
  BannerRecruitImg,
  BannerSeagateImg,
  BannertplinkImg,
};

export default Icons;
