import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import Icons from "../assets/icons";
import ScrollToTop from "../utils/ScrollToTop";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import BannerResize from "../utils/BannerResize";
import SlideComponent from "./SlideComponent";
import { useCookies } from "react-cookie";
interface widthInterface {
  w?: number;
  main?: string;
  hover?: string;
  active?: string;
  background?: string;
  fontColor?: string;
}
const navList = [
  { to: "홈", translation: "home", path: "" },
  { to: "회사 소개", translation: "introduce", path: "introduce" },
  { to: "관계사 소개", translation: "partner", path: "partner" },
  { to: "사업 영역", translation: "business", path: "business" },
  { to: "PR/IR", translation: "news", path: "news" },
  { to: "인재 채용", translation: "recruit", path: "recruit" },
  { to: "고객지원", translation: "cs", path: "cs" },
];

const BannerData = [
  {
    banner: "segate",
    logo: <Icons.BrandSeagateW width={30} height={30} />,
    color: {
      main: "#6FBE4B",
      hover: "#8ae261",
      active: "#599f38",
      background: "#f5fdf1",
      fontColor: "#1d4b08",
    },
    text: [
      { title: "씨게이트 A/S 센터 안내" },
      {
        contentsList: [
          {
            subject: "제품 교환, A/S 문의",
            content: (
              <Link to={`/business/it/seagate`} state={{ delivery: 1 }}>
                바로가기
                <Icons.ArrowRightW />
              </Link>
            ),
          },
        ],
      },
      {
        bgImg: (
          <span className="banner-img">
            <Icons.BannerSeagateImg />
          </span>
        ),
      },
    ],
  },
  {
    banner: "recruit",
    color: {
      main: "#084bc0",
      hover: "#175fdb",
      active: "#003692",
      background: "#e6efff",
      fontColor: "#fff",
    },
    text: [
      { title: "SCGS 신입/경력 채용" },
      {
        contentsList: [
          {
            subject: "공고 페이지",
            content: (
              <>
                <Link to={"/recruit"} state={1}>
                  바로가기
                  <Icons.ArrowRightW />
                </Link>
              </>
            ),
          },
        ],
      },
      {
        bgImg: (
          <span className="banner-img">
            <Icons.BannerRecruitImg />
          </span>
        ),
      },
    ],
  },
];
const BannerItemStyle = styled.ul<widthInterface>`
  .banner-box {
    background-color: ${(props) => props.main};
    width: 100%;
    height: 50px;
    align-content: center;
    color: ${(props) => props.fontColor};
    .banner-conrtainer {
      align-content: center;
      justify-content: space-around;
      display: flex;
      padding: 0 10px;
      .banner-title {
        display: flex;
        font-size: 14px;
        align-content: center;
        align-items: center;
        span:nth-of-type(1) {
          margin-right: 10px;
        }
      }
      .banner-right {
        display: flex;
        .banner-list {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .banner-contents {
            font-size: 14px;
            display: flex;
            :last-of-type {
              .border-segate,
              .border-tplink {
                padding: 2px 10px;
                border: 2px solid #fff;
                /* background-color: ${(props) => props.active}; */
                background-color: #fff;
                color: ${(props) => props.fontColor};
                /* color: #fff; */
                border-radius: 100rem;
                a {
                  svg {
                    margin-right: 5px;
                    path {
                      fill: ${(props) => props.fontColor};
                    }
                  }
                  span {
                  }
                }
              }
              .border-recruit {
                padding: 5px 10px;
                border: 2px solid #fff;
                border-radius: 100rem;
                background-color: ${(props) => props.main};
                a {
                  svg {
                    margin-left: 5px;
                  }
                  span {
                  }
                }
              }
            }
            .banner-subject {
              margin-right: 10px;
              align-self: center;
            }
            .banner-icon {
            }
            .banner-content {
              display: flex;
              align-self: center;
              a {
                display: flex;
                align-items: center;
                svg {
                }
              }
            }
          }
        }
        .banner-img-box {
          margin-left: 20px;
          display: flex;
          .banner-img {
            display: flex;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .banner-box {
      .banner-conrtainer {
        padding: unset;
        .banner-title {
          min-width: 130px;
        }
        .banner-right {
          .banner-list {
            .banner-contents {
              z-index: 1;
              :last-of-type {
                .border-segate,
                .border-tplink {
                  padding: 2px 7px;
                  border: 1px solid #fff;
                  a {
                    svg {
                      margin-right: 2px;
                    }
                  }
                }
                .border-tplink {
                  svg {
                    display: none;
                  }
                }
              }
              .banner-subject {
                display: none;
              }
              .banner-content {
                min-width: unset;
              }
            }
          }
          .banner-img-box {
            position: absolute;
            z-index: 0;
          }
        }
      }
    }
  }
`;
const BannerStyle = styled.ul<widthInterface>`
  transition: 0.3s;
  position: relative;
  font-size: 16px;
  text-align: center;
  align-items: center;
  display: flex;
  /* color: ${(props) => props.fontColor}; */
  .invisible {
    height: 50px;
    display: flex;
    flex-direction: column;
    min-width: 80px;
    font-size: 12px;
    span {
      /* background-color: ${(props) => props.hover}; */
      height: 50%;
      align-content: center;
      transition: 0.3s;
      :hover {
        background-color: #eee;
        /* background-color: ${(props) => props.main}; */
      }
    }
  }
  .banner-left {
    display: flex;
    max-width: calc(100vw - 80px);
    width: 100vw;
  }
  @media (max-width: 768px) {
    position: relative;
    transform: translateY(50px);
    margin-top: 0px;
    .invisible {
      font-size: 10px;
      min-width: 50px;
    }
    .banner-left {
      max-width: calc(100vw - 50px);
    }
  }
`;
const BannerComponent = ({ t, setIdxNum }: any) => {
  const settings = {
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    dots: false,
    centerMode: true,
    centerPadding: "0",
    pauseOnHover: true,
    afterChange: (idxNum: any) => {
      setIdxNum(idxNum);
    },
  };
  return (
    <motion.div
      className="banner-left"
      initial={{ translateY: "-80px", opacity: 0, height: "50px" }}
      animate={{ translateY: "0", opacity: 1, height: "50px" }}
      exit={{
        translateY: "-80px",
        opacity: 0,
        height: "0px",
        overflow: "hidden",
      }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
      }}
    >
      <SlideComponent settings={settings}>
        {BannerData.map((res, idx) => {
          return (
            <BannerItemStyle
              main={res.color.main}
              active={res.color.active}
              hover={res.color.hover}
              background={res.color.background}
              fontColor={res.color.fontColor}
              key={idx}
            >
              <div className="banner-box">
                <div className="banner-conrtainer">
                  <h1 className="banner-title">
                    {res?.logo && <span>{res?.logo}</span>}
                    {res.text[0].title && <span>{res.text[0].title}</span>}
                  </h1>
                  <div className="banner-right">
                    <ul className="banner-list">
                      {res.text[1].contentsList?.map((content: any, conIdx) => {
                        return (
                          <li className="banner-contents" key={conIdx}>
                            {"subject" in content && (
                              <h2 className="banner-subject">
                                <p>{content.subject}</p>
                              </h2>
                            )}
                            {content?.icon !== undefined && (
                              <p className="banner-icon">{content.icon}</p>
                            )}
                            {res.text[1].contentsList && (
                              <p
                                className={`banner-content ${
                                  res.banner === "segate"
                                    ? "border-" + res.banner
                                    : ""
                                }
                                  ${
                                    res.banner === "recruit"
                                      ? "border-" + res.banner
                                      : ""
                                  }
                                `}
                              >
                                {/* ${ res.banner === "tplink" ? "border-" + res.banner : "" } */}
                                {content.content}
                              </p>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    {res.text[2].bgImg && (
                      <p className="banner-img-box">{res.text[2].bgImg}</p>
                    )}
                  </div>
                </div>
              </div>
            </BannerItemStyle>
          );
        })}
      </SlideComponent>
    </motion.div>
  );
};

const HeadNavStyle = styled.header<widthInterface>`
  transition: 0.3s;
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  color: ${(props) => props.color || "white"};
  width: 100%;
  background-color: #fff;
  white-space: nowrap;
  font-size: 16px;
  box-shadow: 0 10px 10px -10px #00000033;
  .inner-box {
    display: flex;
    margin: auto;
    width: 95%;
    max-width: 1200px;
    > li {
      min-width: 100px;
      transition: 0.3s;
      display: flex;
      height: 50px;
      position: relative;
      color: #000;
      :hover nav {
        height: 34px;
      }
      :nth-of-type(1) {
        min-width: 160px;
        padding: 3px 10px;
        margin-right: auto;
      }
      nav {
        display: flex;
        transition: 0.3s;
        position: absolute;
        top: 100%;
        height: 0px;
        transform: translateX(-30%);
        overflow: hidden;
        color: #000000;
        ul {
          background: #ffffffbb;
          overflow: hidden;
          li {
            padding: 5px 20px;
            h1 {
              font-weight: normal;
            }
          }
          span {
            padding: 13px 20px;
            white-space: nowrap;
            margin: auto;
          }
        }
        li {
          transition: 0.3s;
          padding: 13px 20px;
          white-space: nowrap;
          margin: auto;
          position: relative;
          :hover {
            background: #eeeeeebb;
            color: #000000;
          }
        }
      }

      &:first-child p {
        width: 100%;
        height: 100%;
        svg {
          margin: auto;
          width: 100%;
          height: 70%;
        }
      }
      &.active {
        background: #2d59a2;
        color: #fff;
        &:hover {
          transition: 0.1s;
          background: #3e73c8;
        }
        &:active {
          transition: 0.1s;
          background: #20447f;
        }
      }
      img {
        vertical-align: bottom;
        margin-right: 10px;
      }
      p {
        margin: auto;
        display: flex;
        svg {
        }
      }
      &:hover {
        transition: 0.1s;
        background: #eee;
      }
      &:active {
        transition: 0.1s;
        background: #ddd;
      }
    }
  }
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .closeButton {
    display: none;
  }
  .translation {
    height: 30px;
    aspect-ratio: 1;
    position: absolute;
    border-radius: 100px;
    top: 60px;
    right: 10px;
    background: #00226655;
    display: flex;
    transition: 0.3s;
    &:hover {
      background: #002266;
      height: 50px;
    }
    p {
      margin: auto;
      line-height: 1em;
    }
  }
  @media (max-width: 1024px) {
    .inner-box {
      width: 100vw;
    }
  }
  @media (max-width: 768px) {
    position: absolute;
    .inner-box {
      flex-direction: column;
      li:nth-of-type(1) {
        display: flex;
        height: 50px;
        background: #ffffff66;
      }
      li:nth-of-type(1).active {
        background: #00000066;
      }
      li {
        width: 100%;
        height: 0px;
        overflow: hidden;
      }
    }
    &.active {
      width: 100%;
      li {
        height: 50px;
        width: 100%;
      }
      .closeButton {
        transition: 0.3s;
      }
      .translation {
        display: flex;
        height: 50px;
        aspect-ratio: unset;
        position: unset;
        border-radius: unset;
        top: unset;
        right: unset;
        background: #fff;
        color: #000;
        p::before {
          content: "language change : ";
        }
      }
    }
    .closeButton {
      display: flex;
      background: #fff;
      width: 50px;
      transition: 0.3s;
      overflow: hidden;
      margin-left: auto;
      position: absolute;
      right: 0;
      &:hover {
        transition: 0.1s;
        background: #eee;
      }
      &:active {
        transition: 0.1s;
        background: #ddd;
      }
      svg {
        transition: 0.3s;
        margin: auto;
      }
    }
    .translation {
      display: none;
    }
  }
`;
const HeadNav = ({ t, i18n }: any) => {
  const location = useLocation();
  const path = location.pathname.split("/")[1];
  const path2 = location.pathname.split("/")[2];
  const path3 = location.pathname.split("/")[3];
  const pathIdCheck = location.pathname.split("/")[2];
  const [isPopup, setIsPopup] = useState(false);
  const [isRender, setIsRender] = useState(true);
  const [cookies, setCookies, removeCookie] = useCookies();
  const [isOpenedBanner, setIsOpenedBanner] = useState(
    cookies["dayHideCheck"] ? false : cookies["weekHideCheck"] ? false : true
  );
  const [idxNum, setIdxNum] = useState(0);
  useEffect(() => {
    i18n.language === "ko" && isOpenedBanner
      ? BannerResize(true, path)
      : BannerResize(false, path);
  }, [isOpenedBanner, path, i18n.language]);

  const checkPath = () => {
    setIsRender(!isRender);
    if (
      path === "news" ||
      path === "policy" ||
      path === "sitemap" ||
      path === "post" ||
      (path === "recruit" && path2 !== undefined)
    ) {
      location.pathname = "/";
    }
  };

  const getExpiredDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };
  const checkHandle = ({ date }: { date: string }) => {
    switch (date) {
      case "day":
        {
          const expires = getExpiredDate(1);
          setCookies("dayHideCheck", true, { path: "/", expires });
          setIsOpenedBanner(false);
        }
        break;

      case "week":
        {
          const expires = getExpiredDate(7);
          setCookies("weekHideCheck", true, { path: "/", expires });
          setIsOpenedBanner(false);
          removeCookie("dayHideCheck");
        }
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    return () => {
      ScrollToTop();
    };
  }, [path, path2, path3]);
  return (
    <>
      <AnimatePresence>
        {(cookies["weekHideCheck"] === undefined ||
          cookies["weekHideCheck"] === false) &&
          (cookies["dayHideCheck"] === undefined ||
            cookies["dayHideCheck"] === false) &&
          isOpenedBanner &&
          i18n.language === "ko" && (
            <BannerStyle
              className="no-select"
              main={BannerData[idxNum].color.main}
              active={BannerData[idxNum].color.active}
              hover={BannerData[idxNum].color.hover}
              background={BannerData[idxNum].color.background}
              fontColor={BannerData[idxNum].color.fontColor}
            >
              <BannerComponent t={t} idxNum={idxNum} setIdxNum={setIdxNum} />
              <motion.div
                className="invisible pointer"
                initial={{ translateY: "-80px", opacity: 0, height: "50px" }}
                animate={{ translateY: "0", opacity: 1, height: "50px" }}
                exit={{
                  translateY: "-80px",
                  opacity: 0,
                  height: "0px",
                  overflow: "hidden",
                }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                }}
              >
                <span onClick={() => checkHandle({ date: "day" })}>
                  일간 닫기
                </span>
                <span onClick={() => checkHandle({ date: "week" })}>
                  주간 닫기
                </span>
              </motion.div>
            </BannerStyle>
          )}
      </AnimatePresence>
      <HeadNavStyle
        className={`no-select ${isPopup ? "active" : ""}`}
        w={navList.length}
      >
        <motion.ul
          initial={{ translateY: "-80px", opacity: 0 }}
          animate={{ translateY: "0", opacity: 1 }}
          exit={{}}
          transition={{
            ease: "easeInOut",
            duration: 0.5,
          }}
          className="inner-box"
        >
          {navList.map((res, idx) => {
            return res.path !== "news" || isRender ? (
              <li
                onClick={() => setIsPopup(false)}
                key={idx}
                className={
                  path === `${res.path}` && res.to !== "홈" ? "active" : ""
                }
              >
                <p>
                  {res.to === "홈" && isRender ? (
                    <Icons.LogoBlackC />
                  ) : (
                    <Icons.LogoBlackCEn />
                  )}
                  {res.to === "홈" ? <></> : t(res.translation)}
                </p>
                {path !== `${res.path}` || pathIdCheck ? (
                  <Link to={{ pathname: `/${res.path}` }}></Link>
                ) : (
                  <></>
                )}
                {res.path === `business` ? (
                  <nav>
                    <ul>
                      <li>
                        <h1>{t("IT Solution")}</h1>
                        <Link to={"/business/it/dell"} />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h1>{t("AI Solution")}</h1>
                        <Link to={"/business/ai"} />
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <h1>{t("mainSolar")}</h1>
                        <Link to={"/business/solar"} />
                      </li>
                    </ul>
                  </nav>
                ) : (
                  ""
                )}
              </li>
            ) : (
              ""
            );
          })}
          <div
            className="translation"
            onClick={() => {
              isRender ? i18n.changeLanguage("en") : i18n.changeLanguage("ko");
              checkPath();
            }}
          >
            <p>{isRender ? "En" : "한"}</p>
          </div>
          <div
            className="closeButton"
            onClick={() => (isPopup ? setIsPopup(false) : setIsPopup(true))}
          >
            {isPopup ? <Icons.IconClose /> : <Icons.IconMenu />}
          </div>
        </motion.ul>
      </HeadNavStyle>
    </>
  );
};

export default HeadNav;
